import agenda1 from "../../assets/images/products/agenda-1.webp";
import agenda2 from "../../assets/images/products/agenda-2.webp";
import agenda from "../../assets/images/products/agenda.webp";
import badge from "../../assets/images/products/badge.webp";
import bag from "../../assets/images/products/bag.webp";
import bannerPoster1 from "../../assets/images/products/banner-poster-1.webp";
import bannerPoster from "../../assets/images/products/banner-poster.webp";
import book from "../../assets/images/products/book.webp";
import bookmark from "../../assets/images/products/bookmark.webp";
import box1 from "../../assets/images/products/box-1.webp";
import box2 from "../../assets/images/products/box-2.webp";
import box3 from "../../assets/images/products/box-3.webp";
import box4 from "../../assets/images/products/box-4.webp";
import box from "../../assets/images/products/box.webp";
import brochure1 from "../../assets/images/products/brochure-1.webp";
import brochure from "../../assets/images/products/brochure.webp";
import businessCard1 from "../../assets/images/products/business-card-1.webp";
import businessCard from "../../assets/images/products/business-card.webp";
import cap from "../../assets/images/products/cap.webp";
import cargoBox1 from "../../assets/images/products/cargo-box-1.webp";
import cargoBox2 from "../../assets/images/products/cargo-box-2.webp";
import cargoBox3 from "../../assets/images/products/cargo-box-3.webp";
import cargoBox4 from "../../assets/images/products/cargo-box-4.webp";
import cargoBox from "../../assets/images/products/cargo-box.webp";
import catalog1 from "../../assets/images/products/catalog-1.webp";
import catalog from "../../assets/images/products/catalog.webp";
import cdCase1 from "../../assets/images/products/cd-case-1.webp";
import cdCase2 from "../../assets/images/products/cd-case-2.webp";
import cdCase from "../../assets/images/products/cd-case.webp";
import certificate from "../../assets/images/products/certificate.webp";
import cupHolder1 from "../../assets/images/products/cup-holder-1.webp";
import cupHolder2 from "../../assets/images/products/cup-holder-2.webp";
import cupHolder from "../../assets/images/products/cup-holder.webp";
import doorHanger from "../../assets/images/products/door-hanger.webp";
import doypack1 from "../../assets/images/products/doypack-1.webp";
import doypack from "../../assets/images/products/doypack.webp";
import envelope1 from "../../assets/images/products/envelope-1.webp";
import envelope from "../../assets/images/products/envelope.webp";
import folder1 from "../../assets/images/products/folder-1.webp";
import folder from "../../assets/images/products/folder.webp";
import foodPackaging1 from "../../assets/images/products/food-packaging-1.webp";
import foodPackaging2 from "../../assets/images/products/food-packaging-2.webp";
import foodPackaging3 from "../../assets/images/products/food-packaging-3.webp";
import foodPackaging4 from "../../assets/images/products/food-packaging-4.webp";
import foodPackaging5 from "../../assets/images/products/food-packaging-5.webp";
import foodPackaging from "../../assets/images/products/food-packaging.webp";
import handbill from "../../assets/images/products/handbill.webp";
import invitation1 from "../../assets/images/products/invitation-1.webp";
import invitation from "../../assets/images/products/invitation.webp";
import keychain from "../../assets/images/products/keychain.webp";
import label1 from "../../assets/images/products/label-1.webp";
import label2 from "../../assets/images/products/label-2.webp";
import label from "../../assets/images/products/label.webp";
import letterhead from "../../assets/images/products/letterhead.webp";
import magazine1 from "../../assets/images/products/magazine-1.webp";
import magazine from "../../assets/images/products/magazine.webp";
import magnet from "../../assets/images/products/magnet.webp";
import menu from "../../assets/images/products/menu.webp";
import mousepad from "../../assets/images/products/mousepad.webp";
import mug from "../../assets/images/products/mug.webp";
import nameTag1 from "../../assets/images/products/name-tag-1.webp";
import nameTag2 from "../../assets/images/products/name-tag-2.webp";
import nameTag from "../../assets/images/products/name-tag.webp";
import notebook1 from "../../assets/images/products/notebook-1.webp";
import notebook2 from "../../assets/images/products/notebook-2.webp";
import notebook3 from "../../assets/images/products/notebook-3.webp";
import notebook from "../../assets/images/products/notebook.webp";
import notepad from "../../assets/images/products/notepad.webp";
import paperBag1 from "../../assets/images/products/paper-bag-1.webp";
import paperBag2 from "../../assets/images/products/paper-bag-2.webp";
import paperBag3 from "../../assets/images/products/paper-bag-3.webp";
import paperBag from "../../assets/images/products/paper-bag.webp";
import paperCup1 from "../../assets/images/products/paper-cup-1.webp";
import paperCup2 from "../../assets/images/products/paper-cup-2.webp";
import paperCup from "../../assets/images/products/paper-cup.webp";
import pen from "../../assets/images/products/pen.webp";
import placeMat from "../../assets/images/products/place-mat.webp";
import powerbank1 from "../../assets/images/products/powerbank-1.webp";
import powerbank from "../../assets/images/products/powerbank.webp";
import rollUp from "../../assets/images/products/roll-up.webp";
import selfStickNote1 from "../../assets/images/products/self-stick-note-1.webp";
import selfStickNote from "../../assets/images/products/self-stick-note.webp";
import sticker1 from "../../assets/images/products/sticker-1.webp";
import sticker from "../../assets/images/products/sticker.webp";
import tableCalender1 from "../../assets/images/products/table-calender-1.webp";
import tableCalender2 from "../../assets/images/products/table-calender-2.webp";
import tableCalender from "../../assets/images/products/table-calender.webp";
import tshirt from "../../assets/images/products/tshirt.webp";
import usb from "../../assets/images/products/usb.webp";
import wallCalender from "../../assets/images/products/wall-calender.webp";

export const JobCategories = {
  // Temel Kategoriler
  PRINTED_PRODUCTS: "Basılı Ürünler",
  PACKAGING_PRODUCTS: "Ambalaj Ürünleri",
  PROMOTIONAL_PRODUCTS: "Promosyon Ürünleri",
  OTHER: "Diğer"
};

const productsData = {
  title: "Ürünlerimiz",
  subTitle: "Farklı Sektörlere Özel Geniş Ürün Yelpazesi",
  description:
    "Matbaa sektöründeki uzmanlığımızla kaliteli baskı ve ambalaj çözümleri sunuyoruz. İhtiyacınıza uygun ürünleri seçerek detaylı bilgi alabilirsiniz.",
  featuredProductIDs: ["dergi", "karton-poset", "brosur", "kartvizit", "defter", "davetiye", "sticker", "karton-kutu", "takvim", "ajanda"],
  products: [
    // Basılı Ürünler
    {
      id: "dergi",
      title: "Dergi",
      name: "Profesyonel Dergi Baskısı",
      images: [magazine, magazine1],
      description: `
        Profesyonel dergi baskısı çözümlerimizle markanızın prestijini artırın.
        Yüksek kaliteli baskı teknolojileri ve dayanıklı malzemeler kullanılarak
        hazırlanan dergilerimiz, kurumsal yayınlardan tanıtım materyallerine kadar
        geniş bir kullanım alanı sunar.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "90g - 135g Kuşe Kağıt, Mat / Parlak Selefon Kaplama"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, Spot Lak, Kabartma Lak, UV Baskı"
        },
        {
          label: "Boyutlar",
          value: "A4 (21x29.7 cm), A5 (14.8x21 cm), Özel ebat seçenekleri"
        },
        {
          label: "Sayfa Sayısı",
          value: "8 - 200 sayfa arası (isteğe bağlı artırılabilir)"
        },
        {
          label: "Ciltleme Seçenekleri",
          value: "Tel dikiş, Spiral, Amerikan Cilt, Şömiz Kapak"
        },
        {
          label: "Renk Seçenekleri",
          value: "4 Renk CMYK, Pantone Renk Seçenekleri"
        },
        {
          label: "Kağıt Seçenekleri",
          value: "Kuşe, Bristol, Kraft, Özel Dokulu Kağıtlar"
        },
        {
          label: "Ek Özellikler",
          value: "Laminasyon, Varak Yaldız, Kabartma Gofre, Kısmi Lak"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-10 iş günü (Projenin karmaşıklığına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kurumsal Yayınlar",
        "Kültürel ve Sanatsal Organizasyonlar",
        "Eğitim Kurumları ve Etkinlikler",
        "Marka ve Ürün Tanıtımları",
        "Moda, Teknoloji ve Yaşam Dergileri"
      ]
    },
    {
      id: "brosur",
  title: "Broşür",
  name: "Profesyonel Tanıtım Broşürü",
  images: [brochure, brochure1],
  description: `
    Etkili tanıtım ve pazarlama faaliyetleri için profesyonel broşür baskı çözümlerimizle fark yaratın.
    Dikkat çekici tasarımlar ve yüksek kaliteli baskı teknikleriyle hazırlanan broşürlerimiz,
    markanızın mesajını en iyi şekilde yansıtmanıza yardımcı olur.
  `,
  categories: [JobCategories.PRINTED_PRODUCTS],
  specs: [
    {
      label: "Malzeme",
      value: "135g - 170g Kuşe Kağıt, Mat / Parlak Selefon Kaplama"
    },
    {
      label: "Kaplama Seçenekleri",
      value: "Mat / Parlak Selefon, Spot Lak, Kabartma Lak, UV Baskı"
    },
    {
      label: "Boyutlar",
      value: "A4 (21x29.7 cm), A5 (14.8x21 cm), Özel ölçüler"
    },
    {
      label: "Katlama Seçenekleri",
      value: "Tek Katlama, Z-Katlama, C-Katlama, Kapaklı Katlama"
    },
    {
      label: "Baskı Teknikleri",
      value: "Ofset Baskı, Dijital Baskı"
    },
    {
      label: "Renk Seçenekleri",
      value: "4 Renk CMYK, Pantone Renk Seçenekleri"
    },
    {
      label: "Kağıt Seçenekleri",
      value: "Kuşe, Bristol, Kraft, Özel Dokulu Kağıtlar"
    },
    {
      label: "Ek Özellikler",
      value: "Laminasyon, Varak Yaldız, Kabartma Gofre, Kısmi Lak"
    },
    {
      label: "Teslimat Süresi",
      value: "Ortalama 3-7 iş günü (Projenin karmaşıklığına bağlı olarak değişebilir)"
    }
  ],
  usageAreas: [
    "Restoran ve Kafeler",
    "Etkinlik ve Organizasyonlar",
    "Fuar ve Stand Tanıtımları",
    "Ürün ve Hizmet Tanıtımları",
    "Emlak, Sağlık ve Eğitim Sektörleri",
    "Kampanya ve İndirim Duyuruları"
  ],
    },
    {
      id: "el-ilani",
      title: "El İlanı",
      name: "Etkili ve Göz Alıcı El İlanı Baskısı",
      images: [handbill],
      description: `
        Kampanyalarınızı, ürünlerinizi veya etkinliklerinizi tanıtmanın en etkili yollarından biri olan 
        el ilanları, geniş kitlelere ulaşmak için mükemmel bir çözümdür. Profesyonel tasarım ve baskı 
        kalitemizle hedef kitlenizde güçlü bir etki bırakabilirsiniz.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "135g - 250g Kuşe, Mat / Parlak Selefon Kaplama"
        },
        {
          label: "Boyutlar",
          value: "A4 (21x29.7 cm), A5 (14.8x21 cm), A6 (10.5x14.8 cm), Özel ebat seçenekleri"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, Spot Lak, Kabartma Lak"
        },
        {
          label: "Renk Seçenekleri",
          value: "4 Renk CMYK, Pantone Renk Seçenekleri"
        },
        {
          label: "Baskı Tarafı",
          value: "Tek Yönlü veya Çift Yönlü Baskı Seçenekleri"
        },
        {
          label: "Kağıt Seçenekleri",
          value: "Kuşe, Kraft, Geri Dönüştürülmüş Kağıt Seçenekleri"
        },
        {
          label: "Ek Özellikler",
          value: "Laminasyon, Varak Yaldız, Kabartma Gofre, Kısmi Lak"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 3-7 iş günü (Projenin karmaşıklığına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Restoran ve Kafe Tanıtımları",
        "Etkinlik ve Organizasyon Duyuruları",
        "Kampanya ve İndirim Bilgilendirmeleri",
        "Açılış ve Davetiye Duyuruları",
        "Siyasi Kampanya ve Seçim Çalışmaları",
        "Eğitim Kurumları ve Kurs Tanıtımları"
      ],
    },
    {
      id: "katalog",
      title: "Katalog",
      name: "Şık ve Profesyonel Katalog Tasarımı ve Baskısı",
      images: [catalog, catalog1],
      description: `
        Ürün ve hizmetlerinizi tanıtmanın en etkili yollarından biri olan kataloglar, firmanızın 
        kurumsal kimliğini güçlendirmek ve müşterilerinizde profesyonel bir izlenim bırakmak için 
        idealdir. Geniş içerik yapısı ve zengin tasarım imkanlarıyla kataloglarımız, markanızın değerini 
        en iyi şekilde yansıtacak.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "90g - 170g Kuşe İç Sayfa, 250g - 350g Kuşe Kapak"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, Spot Lak, Kabartma Lak, UV Baskı"
        },
        {
          label: "Boyutlar",
          value: "A4 (21x29.7 cm), A5 (14.8x21 cm), Özel ebat seçenekleri"
        },
        {
          label: "Sayfa Sayısı",
          value: "8 - 200 sayfa arası (isteğe bağlı artırılabilir)"
        },
        {
          label: "Ciltleme Seçenekleri",
          value: "Amerikan Cilt, Tel Dikiş, Spiral Ciltleme"
        },
        {
          label: "Renk Seçenekleri",
          value: "4 Renk CMYK, Pantone Renk Seçenekleri"
        },
        {
          label: "Kağıt Seçenekleri",
          value: "Kuşe, Bristol, Kraft, Geri Dönüştürülmüş Kağıt"
        },
        {
          label: "Ek Özellikler",
          value: "Laminasyon, Varak Yaldız, Kabartma Gofre, Kısmi Lak"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 7-14 iş günü (Projenin karmaşıklığına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Ürün ve Hizmet Tanıtımları",
        "Fuar ve Organizasyonlarda Dağıtım",
        "Kurumsal Kimlik Tanıtımı",
        "Emlak, Otomotiv, Mobilya ve Perakende Sektörü",
        "Restoran ve Otel Menülerinde"
      ]
    }, 
    {
      id: "kitap",
      title: "Kitap",
      name: "Profesyonel Kitap Baskı ve Ciltleme Hizmetleri",
      images: [book],
      description: `
        Roman, araştırma kitabı, akademik yayın veya şiir kitabı gibi her türden eserinizi 
        yüksek kaliteli baskı çözümlerimizle hayata geçirin. Dayanıklı ciltleme seçenekleri, 
        zengin kağıt türleri ve estetik kaplama teknikleri ile kitaplarınız profesyonel bir 
        görünüm kazanır.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "70g - 150g 1. Hamur veya Kitap Kağıdı, 250g - 350g Kuşe Kapak"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, Spot Lak, UV Baskı"
        },
        {
          label: "Boyutlar",
          value: "A4 (21x29.7 cm), A5 (14.8x21 cm), Özel ebat seçenekleri"
        },
        {
          label: "Sayfa Sayısı",
          value: "16 - 500 sayfa arası (isteğe bağlı artırılabilir)"
        },
        {
          label: "Ciltleme Seçenekleri",
          value: "Amerikan Cilt, Tel Dikiş, Sert Kapak, Şömiz Kapak"
        },
        {
          label: "Renk Seçenekleri",
          value: "Siyah Beyaz Baskı, Renkli Baskı, Karışık Baskı"
        },
        {
          label: "Kağıt Seçenekleri",
          value: "1. Hamur, Kitap Kağıdı, Kuşe, Kraft, Geri Dönüştürülmüş Kağıt"
        },
        {
          label: "Ek Özellikler",
          value: "Varak Yaldız, Kabartma Gofre, Ayraç Eklemeleri"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 7-15 iş günü (Projenin kapsamına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Roman ve Edebiyat Kitapları",
        "Akademik ve Bilimsel Yayınlar",
        "Şiir, Deneme ve Sanat Kitapları",
        "Eğitim ve Ders Kitapları",
        "Süreli Yayınlar ve Araştırma Raporları"
      ]
    },
    {
      id: "defter",
      title: "Defter",
      name: "Özel Tasarım ve Kişisel Defter Baskısı",
      images: [notebook, notebook1, notebook2, notebook3],
      description: `
        Not almak, plan yapmak veya özel tasarımlı kurumsal defterler üretmek için yüksek kaliteli 
        defter baskı çözümlerimiz ideal bir tercih. Dayanıklı kaplama, özel kapak tasarımı ve 
        kaliteli kağıt seçenekleriyle defterleriniz profesyonel ve şık görünecek.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "70g - 120g 1. Hamur Kağıt, 250g - 350g Kuşe Kapak"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, Spot Lak, Kabartma Lak"
        },
        {
          label: "Boyutlar",
          value: "A4 (21x29.7 cm), A5 (14.8x21 cm), Özel ebat seçenekleri"
        },
        {
          label: "Sayfa Sayısı",
          value: "50 - 200 sayfa arası (isteğe bağlı artırılabilir)"
        },
        {
          label: "Ciltleme Seçenekleri",
          value: "Spiral, Tel Dikiş, Sert Kapak, Amerikan Cilt"
        },
        {
          label: "Renk Seçenekleri",
          value: "Siyah Beyaz Baskı, Renkli Baskı"
        },
        {
          label: "Kağıt Seçenekleri",
          value: "1. Hamur, Ivory, Geri Dönüştürülmüş Kağıt"
        },
        {
          label: "Ek Özellikler",
          value: "Lastik Kapama, Kalem Tutacağı, İç Cep Seçenekleri"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-10 iş günü (Projenin karmaşıklığına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kurumsal Promosyon Defterleri",
        "Toplantı ve Seminer Not Defterleri",
        "Okul ve Eğitim Defterleri",
        "Kişisel Not ve Planlama Defterleri",
        "Hediye ve Özel Tasarım Defterler"
      ]
    },
    {
      id: "afis-poster",
      title: "Afiş / Poster",
      name: "Etkileyici Afiş ve Poster Tasarımı",
      images: [bannerPoster, bannerPoster1],
      description: `
        Markanızı öne çıkaracak dikkat çekici afiş ve poster baskı çözümlerimizle etkinliklerinizi,
        kampanyalarınızı ve tanıtımlarınızı daha etkili hale getirin. Canlı renkler, yüksek çözünürlükte
        baskı kalitesi ve dayanıklı malzeme seçenekleriyle mesajınızı en iyi şekilde iletebilirsiniz.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "150g - 250g Kuşe Kağıt, Fotoğraf Kağıdı, Vinil Branda"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Spot Lak"
        },
        {
          label: "Boyutlar",
          value: "A3 (29.7x42 cm), A2 (42x59.4 cm), A1 (59.4x84.1 cm), A0 (84.1x118.9 cm), Özel ebat seçenekleri"
        },
        {
          label: "Baskı Teknolojisi",
          value: "Ofset, Dijital Baskı, UV Baskı"
        },
        {
          label: "Renk Seçenekleri",
          value: "4 Renk CMYK, Pantone Renk Seçenekleri"
        },
        {
          label: "Kağıt Seçenekleri",
          value: "Kuşe, Fotoğraf Kağıdı, Vinil, Kanvas"
        },
        {
          label: "Ek Özellikler",
          value: "Laminasyon, Çerçeveleme, Katlama ve Paketleme Seçenekleri"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 3-7 iş günü (Projenin boyutuna ve sipariş miktarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Etkinlik ve Konser Afişleri",
        "Film ve Tiyatro Posterleri",
        "Mağaza İndirim ve Kampanya Duyuruları",
        "Kurumsal ve Sosyal Mesaj İletimleri",
        "Eğitim ve Bilgilendirme Afişleri"
      ]
    },    
    {
      id: "takvim",
      title: "Takvim",
      name: "Masaüstü ve Duvar Takvimleri",
      images: [tableCalender, wallCalender, tableCalender1, tableCalender2],
      description: `
        Hem kurumsal hem de kişisel kullanım için tasarlanan şık ve fonksiyonel takvim modellerimiz,
        markanızın prestijini artırırken müşterilerinizin yıl boyunca göz önünde olmasını sağlar.
        Masaüstü ve duvar modellerimizle zamana değer katın!
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "170g - 300g Kuşe Kağıt, Kalın Kapak Seçenekleri"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Spot Lak"
        },
        {
          label: "Boyutlar",
          value: "Masaüstü (A5), Duvar (A3, A2, Özel ölçüler)"
        },
        {
          label: "Baskı Seçenekleri",
          value: "Çift Yön Baskı, Fotoğraf Kalitesinde Dijital Baskı"
        },
        {
          label: "Renk Seçenekleri",
          value: "4 Renk CMYK, Pantone Renk Seçenekleri"
        },
        {
          label: "Bağlama Seçenekleri",
          value: "Spiral, Tel Dikiş, Ciltleme"
        },
        {
          label: "Ek Özellikler",
          value: "Tarih Çizelgesi, Özel Gün İşaretleme, Logolu Tasarım Seçenekleri"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Projenin boyutuna ve sipariş miktarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kurumsal Hediyeler",
        "Müşteri Promosyonları",
        "Kişisel Kullanım",
        "Eğitim Kurumları",
        "Reklam ve Tanıtım Kampanyaları"
      ]
    },
    {
      id: "ajanda",
      title: "Ajanda",
      name: "Klasik ve Kurumsal Ajandalar",
      images: [agenda, agenda1, agenda2],
      description: `
        Profesyonel iş yaşamında, eğitim alanında ve kişisel kullanımda vazgeçilmez olan ajandalarımız,
        kaliteli malzeme ve estetik tasarımıyla dikkat çeker. Markanızı ön plana çıkarmak ve prestijinizi artırmak için 
        logolu ve kişiselleştirilmiş ajanda modellerimizi tercih edebilirsiniz.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Suni Deri, Termo Deri, Kumaş Kaplama, Karton Kapak"
        },
        {
          label: "İç Kağıt Seçenekleri",
          value: "70g - 100g Ivory, 1. Hamur Kağıt"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Sıcak Yaldız Baskı"
        },
        {
          label: "Boyutlar",
          value: "A5, A4, Özel ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "Logo Baskısı, Kabartma Baskı, Lazer Baskı, Serigrafi"
        },
        {
          label: "Sayfa Düzeni",
          value: "Günlük, Haftalık, Aylık Planlayıcı Seçenekleri"
        },
        {
          label: "Ek Özellikler",
          value: "Kalem Tutucu, Elastik Kapatma, İsim Baskı, Cep Bölmesi, Şerit Ayraç"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 7-10 iş günü (Projenin boyutuna ve sipariş miktarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kurumsal Hediyeler",
        "Ofis Kullanımı",
        "Eğitim Kurumları",
        "Kongre ve Seminer Etkinlikleri",
        "Promosyon Kampanyaları"
      ]
    },
    {
      id: "davetiye",
      title: "Davetiye",
      name: "Özel Tasarım ve Klasik Davetiyeler",
      images: [invitation, invitation1],
      description: `
        Özel günlerinize anlam katacak şık ve estetik davetiyelerimizle etkinliklerinizi unutulmaz kılın.
        Düğün, nişan, sünnet, açılış, davet ve organizasyonlarınız için kişiselleştirilebilir tasarım seçenekleriyle 
        hayalinizdeki davetiyeye kavuşun. Kaliteli malzeme ve özenli işçilikle hazırlanan modellerimiz markanızın prestijini artırır.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "170g - 350g Kuşe Kağıt, Tuale Kağıt, Kraft Kağıt, Metalize Kağıt"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, Kabartma Lak, Sıcak Yaldız, Varak Baskı"
        },
        {
          label: "Boyutlar",
          value: "A5, A6, Kare, Uzun ve Dar, Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "Tek Yön / Çift Yön Baskı, Altın / Gümüş Yaldız, Renkli veya Siyah-Beyaz Baskı"
        },
        {
          label: "Ek Özellikler",
          value: "Zarflı / Zarfsız Seçenekler, Kurdele, Jüt İp, Pul Mühür, Kese İçerisinde Sunum"
        },
        {
          label: "Kişiselleştirme",
          value: "İsim ve Tarih Özelleştirme, Logo ve Kurumsal Kimlik Tasarımı"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-10 iş günü (Tasarım ve sipariş miktarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Düğün ve Nişan Törenleri",
        "Açılış ve Davet Organizasyonları",
        "Kurumsal Etkinlikler",
        "Sünnet Törenleri",
        "Mezuniyet ve Kutlama Etkinlikleri"
      ]
    },
    {
      id: "urun-etiketi",
      title: "Ürün Etiketi",
      name: "Kişiselleştirilebilir Ürün Etiketleri",
      images: [label, label1, label2],
      description: `
        Ürünlerinizin kalitesini ve profesyonelliğini yansıtan şık ve dayanıklı etiket tasarımlarımızla markanızı ön plana çıkarın.
        Gıda, kozmetik, tekstil ve endüstriyel ürünler için farklı malzeme ve tasarım seçenekleriyle her ihtiyaca özel çözümler sunuyoruz.
        Ürün bilgilerinizi en etkili şekilde sergileyerek müşterilerinizin dikkatini çekin!
      `,
      categories: [JobCategories.PRINTED_PRODUCTS, JobCategories.PACKAGING_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Kuşe Kağıt, PVC, PP (Polipropilen), Metalize Kağıt, Kraft Kağıt"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Kabartma Lak, Soğuk / Sıcak Yaldız"
        },
        {
          label: "Boyutlar",
          value: "Özel ölçüler, Yuvarlak, Kare, Dikdörtgen, Oval"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Altın/Gümüş Yaldız, Şeffaf Baskı"
        },
        {
          label: "Yapışkan Seçenekleri",
          value: "Kuvvetli Yapışkan, Kolay Sökülebilir Yapışkan, Tekrar Yapışabilen Etiket"
        },
        {
          label: "Kullanım Alanları",
          value: "İç Mekan / Dış Mekan, Suya ve Neme Dayanıklı Seçenekler"
        },
        {
          label: "Ek Özellikler",
          value: "Barkod / QR Kod Baskı, Delikli veya Deliksiz Kesim, Numaralandırma Seçenekleri"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 4-7 iş günü (Projenin boyutuna ve sipariş miktarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Gıda Ürünleri",
        "Kozmetik Ürünleri",
        "Tekstil ve Moda Ürünleri",
        "İçecek Şişeleri ve Kavanozlar",
        "Endüstriyel Ürünler",
        "El Yapımı ve Hediyelik Ürünler"
      ]
    },
    {
      id: "karton-kutu",
      title: "Karton Kutu",
      name: "Dayanıklı ve Estetik Karton Kutular",
      images: [box, box1, box2, box3, box4],
      description: `
        Ürünlerinizi güvenle saklamak ve şık bir şekilde sunmak için tasarlanan karton kutu modellerimiz, 
        dayanıklı yapısıyla her sektörde tercih edilmektedir. Gıda, kozmetik, tekstil ve e-ticaret ürünleri için 
        özel tasarım ve kişiselleştirme seçenekleriyle markanızı en iyi şekilde yansıtabilirsiniz.
      `,
      categories: [JobCategories.PACKAGING_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Kraft Karton, Mukavva, Kuşe Kaplamalı Karton, Mikro Oluklu Mukavva"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Spot Lak, Soğuk / Sıcak Yaldız"
        },
        {
          label: "Boyutlar",
          value: "Özel ölçüler, Standart Ebatlar (Küçük, Orta, Büyük)"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Kabartma Baskı, Altın/Gümüş Yaldız, Lazer Kesim"
        },
        {
          label: "Kullanım Alanları",
          value: "Gıda, Kozmetik, Tekstil, Hediyelik Ürünler, E-Ticaret Kargo Kutuları"
        },
        {
          label: "Kilit ve Kapanma Mekanizması",
          value: "Kilitli Kapak, Mıknatıslı Kapanma, Çekmece Tipi, Yapışkan Bantlı"
        },
        {
          label: "Ek Özellikler",
          value: "Pencereli Tasarım, Tutma Kulbu, İç Bölmeli Tasarım, Logo ve Kurumsal Kimlik Baskısı"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 7-10 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Gıda ve İçecek Ürünleri",
        "Kozmetik ve Bakım Ürünleri",
        "Tekstil ve Aksesuar Ürünleri",
        "Promosyon ve Hediyelik Eşyalar",
        "E-Ticaret Kargo Gönderimleri"
      ]
    },
    {
      id: "doypack",
      title: "Doypack",
      name: "Esnek ve Dayanıklı Doypack Ambalajlar",
      images: [doypack, doypack1],
      description: `
        Fonksiyonel ve estetik tasarımıyla dikkat çeken doypack ambalajlarımız, 
        gıda, kozmetik ve kimya sektörlerinde sıklıkla tercih edilmektedir. 
        Yüksek bariyer koruması, sağlam yapısı ve uzun raf ömrü sağlayan özellikleriyle ürünlerinizi güvenle koruyabilirsiniz.
        Doypack ambalajlarımız, kilitli kapama sistemi sayesinde pratik kullanım sağlar ve ürünlerin tazeliğini korur.
      `,
      categories: [JobCategories.PACKAGING_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "PET/ALU/PE, Kraft Kağıt, Metalize Film, Transparan Film"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Kaplama, UV Lak, Metalize Kaplama"
        },
        {
          label: "Boyutlar",
          value: "100 ml - 5 Litre arası farklı ebat seçenekleri"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Metalik Yaldız Baskı, Şeffaf Pencereli Tasarım"
        },
        {
          label: "Kilit Mekanizması",
          value: "Ziplock (Kilitli), Dökme Ağzı, Delikli Asma Aparatı"
        },
        {
          label: "Bariyer Özellikleri",
          value: "Neme, Oksijene ve Güneş Işığına Dayanıklı"
        },
        {
          label: "Ek Özellikler",
          value: "Yırtma Çentikli, Özel Etiket ve Logo Baskısı, Dökme Kapatma Aparatı"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-8 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kahve ve Çay Ürünleri",
        "Kuruyemiş ve Atıştırmalıklar",
        "Baharat ve Kuru Gıda Ürünleri",
        "Kimyasal Ürünler (Gübre, Toz Ürünler)",
        "Kozmetik Ürünleri (Maske, Krem, Sabun)",
        "Evcil Hayvan Mamaları"
      ]
    },
    {
      id: "sticker",
      title: "Sticker",
      name: "Özelleştirilebilir Sticker ve Etiket Tasarımları",
      images: [sticker, sticker1],
      description: `
        Markanızı tanıtmak, ürünlerinizi etiketlemek veya dekoratif amaçlarla kullanmak için tasarlanan sticker modellerimiz,
        dayanıklı malzemesi ve şık tasarımlarıyla dikkat çeker. İç ve dış mekanda kullanıma uygun olan stickerlarımız,
        suya, neme ve güneş ışığına dayanıklı özellikleriyle uzun ömürlü kullanım sağlar.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS, JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Kuşe Kağıt, PVC, PP (Polipropilen), Şeffaf Film, Metalize Kağıt"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Kabartma Lak, Soğuk / Sıcak Yaldız"
        },
        {
          label: "Boyutlar",
          value: "Yuvarlak, Kare, Dikdörtgen, Oval, Özel Kesim"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Şeffaf Baskı, Altın/Gümüş Yaldız"
        },
        {
          label: "Yapışkan Seçenekleri",
          value: "Kuvvetli Yapışkan, Kolay Sökülebilir Yapışkan, Tekrar Yapışabilen Sticker"
        },
        {
          label: "Kullanım Özellikleri",
          value: "İç Mekan / Dış Mekan, Suya ve Neme Dayanıklı Seçenekler"
        },
        {
          label: "Ek Özellikler",
          value: "Kırılgan Sticker (Güvenlik Amaçlı), Barkod / QR Kod Baskı, Numaralandırma Seçenekleri"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 4-7 iş günü (Projenin boyutuna ve sipariş miktarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Ürün Etiketleme",
        "Promosyon Kampanyaları",
        "Araç Stickerları",
        "Mağaza Vitrin ve Cam Tasarımları",
        "Kırtasiye ve Dekoratif Tasarımlar",
        "Kargo ve Paketleme Ürünleri"
      ]
    },
    {
      id: "bloknot",
      title: "Bloknot",
      name: "Kişiselleştirilebilir Bloknot Modelleri",
      images: [notepad],
      description: `
        Ofis, eğitim ve promosyon amaçlı kullanıma uygun bloknotlarımız, pratik ve işlevsel tasarımıyla dikkat çeker. 
        Logolu, kurumsal kimlik tasarımına uygun veya kişisel not alma ihtiyaçlarına özel bloknot modellerimiz,
        hem şık hem de profesyonel bir görünüm sağlar.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "70g - 120g 1. Hamur Kağıt, Ivory Kağıt, Geri Dönüşümlü Kağıt"
        },
        {
          label: "Kapak Seçenekleri",
          value: "Karton Kapak, PVC Kapak, Sert Kapak, Spiral Kapak"
        },
        {
          label: "Boyutlar",
          value: "A4, A5, A6, Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Kabartma Baskı, Altın/Gümüş Yaldız"
        },
        {
          label: "Sayfa Düzeni",
          value: "Çizgili, Kareli, Düz, Tarih Çizelgeli, Ajanda Düzeni"
        },
        {
          label: "Bağlama Seçenekleri",
          value: "Spiral, Tutkallı, Dikişli, Ciltleme"
        },
        {
          label: "Ek Özellikler",
          value: "Yapışkanlı Not Sayfaları, Ayraç, Kurumsal Kimlik Baskısı, Logolu Tasarım"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Projenin boyutuna ve sipariş miktarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Ofis ve Kurumsal Kullanım",
        "Eğitim Kurumları",
        "Promosyon ve Hediyelik Ürünler",
        "Etkinlik ve Toplantılar",
        "Kişisel Not Alma ve Planlama"
      ]
    },
    {
      id: "cepli-dosya",
      title: "Cepli Dosya",
      name: "Profesyonel ve Kurumsal Cepli Dosyalar",
      images: [folder, folder1],
      description: `
        Cepli dosyalar, evraklarınızı düzenli ve şık bir şekilde saklamanın yanı sıra 
        kurumsal kimliğinizi sergilemek için mükemmel bir çözümdür.  
        Toplantılar, seminerler ve promosyon amaçlı kullanıma uygun olan modellerimiz,
        firmanızın prestijini artırırken belgelerinizi korumanıza da yardımcı olur.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "300g - 400g Kuşe Karton, Mat Kuşe, Kraft Karton"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, Spot Lak, UV Lak, Kabartma Baskı"
        },
        {
          label: "Boyutlar",
          value: "A4 Standart (Dosya İçin Uygun), A5, Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Altın/Gümüş Yaldız, Kabartma Logo Baskısı"
        },
        {
          label: "Cepli Seçenekler",
          value: "Tek Cepli, Çift Cepli, Kartvizit Yuvalı"
        },
        {
          label: "Ek Özellikler",
          value: "Kurumsal Logo ve Kimlik Tasarımı, Kartvizit Bölmesi, İç Kapak Not Bölmesi"
        },
        {
          label: "Kullanım Alanları",
          value: "Toplantılar, Seminerler, Eğitim Kurumları, Etkinlikler ve Fuarlar"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kurumsal Kimlik ve Marka Tanıtımı",
        "Ofis ve Arşivleme",
        "Eğitim Kurumları",
        "Fuar, Kongre ve Seminer Etkinlikleri",
        "Müşteri ve Proje Sunumları"
      ]
    },
    {
      id: "menu",
      title: "Menü",
      name: "Restoran ve Kafe İçin Şık Menü Tasarımları",
      images: [menu],
      description: `
        Restoran, kafe, otel ve bar gibi işletmeler için tasarlanan menülerimiz,
        hem görsel açıdan şık hem de dayanıklı yapısıyla uzun ömürlü kullanım sağlar.
        Markanızın kimliğini yansıtan özel tasarım seçenekleriyle menüleriniz müşteri deneyimini daha etkili hale getirir.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "350g Kuşe Kağıt, PVC Kaplamalı Karton, Ahşap Kapak, Deri Kapak"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Kabartma Baskı, Lamine Kaplama"
        },
        {
          label: "Boyutlar",
          value: "A3, A4, A5, Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Altın/Gümüş Yaldız, Kabartma Logo Baskısı"
        },
        {
          label: "Bağlama ve Ciltleme Seçenekleri",
          value: "Spiral, Vidalı, Klasik Kitap Ciltleme, Deri Kapaklı Tasarım"
        },
        {
          label: "Ek Özellikler",
          value: "Leke Tutmayan Kaplama, Suya Dayanıklı Seçenekler, Sayfa Koruma PVC Kılıf"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-10 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Restoran ve Kafeler",
        "Otel ve Tatil Köyleri",
        "Bar ve Pub'lar",
        "Pastaneler ve Fırınlar",
        "Organizasyon ve Etkinlik Hizmetleri"
      ]
    },
    {
      id: "amerikan-servis",
      title: "Amerikan Servis",
      name: "Şık ve Fonksiyonel Amerikan Servis Modelleri",
      images: [placeMat],
      description: `
        Restoran, kafe ve otel gibi işletmeler için tasarlanan Amerikan servis modellerimiz,
        masa düzeninizi şıklaştırırken aynı zamanda hijyen ve temizlik avantajı sağlar.
        Tek kullanımlık veya dayanıklı malzemelerle üretilen Amerikan servisler,
        markanızı tanıtmanın etkili bir yoludur.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "90g - 170g Kuşe Kağıt, Kraft Kağıt, Lamine Karton, PVC Malzeme"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, Suya Dayanıklı Laminasyon"
        },
        {
          label: "Boyutlar",
          value: "35x50 cm (Standart), 40x60 cm veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Tek Yön / Çift Yön Baskı, Logolu ve Tasarımlı Seçenekler"
        },
        {
          label: "Kullanım Özellikleri",
          value: "Tek Kullanımlık, Silinebilir ve Dayanıklı Modeller"
        },
        {
          label: "Ek Özellikler",
          value: "Reklam ve Kampanya Alanı, Menü Bilgileri, Oyun ve Boyama Alanı (Çocuk Menüler İçin)"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Restoran ve Kafeler",
        "Otel ve Tatil Köyleri",
        "Pastane ve Fırınlar",
        "Fast Food Zincirleri",
        "Organizasyon ve Etkinlik Hizmetleri"
      ]
    },
    {
      id: "kitap-ayraci",
      title: "Kitap Ayracı",
      name: "Özel Tasarım ve Kişiselleştirilebilir Kitap Ayraçları",
      images: [bookmark],
      description: `
        Kitap severler için vazgeçilmez bir aksesuar olan kitap ayraçlarımız,
        estetik tasarımları ve dayanıklı yapısıyla dikkat çeker.  
        Promosyon ürünü, etkinlik hediyesi veya kişisel kullanım amacıyla
        özel tasarım kitap ayraçlarıyla markanızı en etkili şekilde tanıtabilirsiniz.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS, JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "300g - 400g Kuşe Karton, Kraft Kağıt, PVC, Ahşap, Metal"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Kabartma Baskı, Altın/Gümüş Yaldız"
        },
        {
          label: "Boyutlar",
          value: "5x15 cm (Standart), 4x20 cm, Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Tek Yön / Çift Yön Baskı, Fotoğraf Kalitesinde Görseller"
        },
        {
          label: "Ek Özellikler",
          value: "Püsküllü Tasarım, Kurdele Detayı, Delikli veya Asma Halkalı Seçenekler"
        },
        {
          label: "Kullanım Alanları",
          value: "Kütüphane Etkinlikleri, Promosyon ve Tanıtım Ürünleri, Kitapçılar ve Yayın Evleri"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kitapçılar ve Yayın Evleri",
        "Eğitim Kurumları",
        "Kırtasiye ve Hediye Mağazaları",
        "Promosyon ve Tanıtım Kampanyaları",
        "Kütüphane Etkinlikleri ve Organizasyonlar"
      ]
    },
    {
      id: "magnet",
      title: "Magnet",
      name: "Kişiselleştirilebilir Magnet Tasarımları",
      images: [magnet],
      description: `
        Ev, ofis ve iş yerlerinde dekoratif ve tanıtım amaçlı kullanılan magnetlerimiz,
        şık tasarımı ve dayanıklı yapısıyla dikkat çeker. Promosyon ürünleri, davetiye alternatifleri
        ve hediyelik seçenekleriyle geniş bir kullanım alanı sunar. Markanızı en etkili şekilde tanıtmak için
        özel tasarım magnetlerimiz ideal çözümdür.
      `,
      categories: [JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Magnet Kağıt, PVC Kaplama, Ahşap Magnet, Metal Magnet"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Kabartma Baskı"
        },
        {
          label: "Boyutlar",
          value: "5x5 cm, 7x10 cm, 10x15 cm veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Fotoğraf Kalitesinde Baskı, Metalik Renk Seçenekleri"
        },
        {
          label: "Ek Özellikler",
          value: "Takvimli Magnet, Notluklu Magnet, Açacaklı Magnet, Fotoğraf Çerçeveli Magnet"
        },
        {
          label: "Kullanım Alanları",
          value: "Mutfak, Buzdolabı, Ofis Panoları, Metal Yüzeyler"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Restoran ve Kafe Tanıtımları",
        "Düğün ve Nişan Hatıraları",
        "Kurumsal Promosyon Ürünleri",
        "Eğitim Kurumları ve Etkinlikler",
        "Kırtasiye ve Hediyelik Eşya Mağazaları"
      ]
    },
    {
      id: "kapi-askisi",
      title: "Kapı Askısı",
      name: "Kişiselleştirilebilir Kapı Askısı Tasarımları",
      images: [doorHanger],
      description: `
        Otel, ofis ve organizasyonlarda sıkça tercih edilen kapı askılarımız,
        şık tasarımı ve dayanıklı yapısıyla dikkat çeker. "Rahatsız Etmeyin", 
        "Oda Temizlensin" gibi klasik mesajların yanı sıra markanızı tanıtmak için 
        özelleştirilebilir tasarım seçenekleriyle kapı askılarımız fark yaratır.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS, JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "300g - 400g Kuşe Karton, PVC, Kraft Kağıt, Plastik veya Ahşap"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Kabartma Baskı"
        },
        {
          label: "Boyutlar",
          value: "10x25 cm (Standart), 8x20 cm, Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Tek Yön / Çift Yön Baskı, Fotoğraf Kalitesinde Görseller"
        },
        {
          label: "Delik ve Askı Tasarımı",
          value: "Standart Kapı Askı Deliği, Yuvarlak veya Özel Kesim"
        },
        {
          label: "Ek Özellikler",
          value: "Suya ve Neme Dayanıklı Seçenekler, Çift Taraflı Mesaj Tasarımı"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Otel ve Konaklama Tesisleri",
        "Ofis ve İş Yerleri",
        "Kongre ve Seminer Organizasyonları",
        "Spor Merkezleri ve Spa Tesisleri",
        "Eğitim Kurumları ve Kampüs Alanları"
      ]
    },
    {
      id: "sertifika-diploma",
      title: "Sertifika/Diploma",
      name: "Özel Tasarım Sertifika ve Diploma Modelleri",
      images: [certificate],
      description: `
        Eğitim kurumları, kurslar, seminerler ve şirket içi başarı ödülleri için tasarlanan 
        sertifika ve diploma modellerimiz, şık tasarımı ve kaliteli malzemesiyle kalıcı bir hatıra oluşturur. 
        Kişiselleştirilebilir tasarım seçenekleriyle başarıyı ödüllendiren en etkili çözüm.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "300g - 400g Kuşe Karton, Ivory Kağıt, Tuale Kağıt, Metalize Kağıt"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Kabartma Baskı, Sıcak Yaldız"
        },
        {
          label: "Boyutlar",
          value: "A4 (Standart), A3, A5 veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Altın/Gümüş Yaldız, Hologramlı Baskı"
        },
        {
          label: "Ek Özellikler",
          value: "Laklı Logolu Tasarım, Kabartma Baskı, İmza Alanı, Çerçeve veya Kapaklı Sunum Seçeneği"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Üniversite ve Okul Mezuniyetleri",
        "Kurs ve Seminer Katılım Belgeleri",
        "Şirket İçi Başarı ve Ödül Sertifikaları",
        "Spor ve Kültürel Etkinlik Sertifikaları",
        "Dernek ve Vakıf Üyelik Belgeleri"
      ]
    },
    {
      id: "yaka-karti",
      title: "Yaka Kartı",
      name: "Özel Tasarım ve Kişiselleştirilebilir Yaka Kartları",
      images: [nameTag, nameTag1, nameTag2],
      description: `
        Etkinliklerde, kurumsal organizasyonlarda ve ofis ortamlarında kimlik tanıtımı için 
        kullanılan yaka kartlarımız, şık tasarımı ve dayanıklı yapısıyla dikkat çeker. 
        Logolu, isim baskılı ve kişiselleştirilebilir tasarımlarımızla hem profesyonel hem de estetik bir çözüm sunuyoruz.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS, JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "PVC, Karton Kaplama, Metal, Pleksi, Sert Plastik"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Kabartma Baskı"
        },
        {
          label: "Boyutlar",
          value: "8.5x5.5 cm (Standart), 9x6 cm, Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Tek Yön / Çift Yön Baskı, Fotoğraf ve QR Kod Ekleme"
        },
        {
          label: "Askı ve Aparatlar",
          value: "İpli Askı, Klipsli Askı, Mıknatıslı Toka, Rozet İğneli Tasarım"
        },
        {
          label: "Ek Özellikler",
          value: "Plastik Kılıf, Şeffaf PVC Koruma, Delikli veya Asma Aparatlı Tasarım"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 4-6 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kongre ve Seminer Organizasyonları",
        "Ofis ve Kurumsal Ortamlar",
        "Fuar ve Etkinlik Katılımcı Kartları",
        "Öğrenci ve Personel Kimlik Kartları",
        "Oteller, Restoranlar ve Mağazalar"
      ]
    },
    {
      id: "zarf",
      title: "Zarf",
      name: "Diplomat ve Torba Zarf Çeşitleri",
      images: [envelope, envelope1],
      description: `
        Kurumsal yazışmalarda, resmi belgelerin taşınmasında ve promosyon amaçlı kullanımlarda tercih edilen 
        diplomat ve torba zarf modellerimiz, şık tasarımı ve kaliteli malzemesiyle dikkat çeker.  
        Farklı boyut ve kapak tasarımlarıyla her türlü ihtiyaca uygun zarf modelleri sunuyoruz.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "90g - 150g 1. Hamur Kağıt, Kraft Kağıt, Ivory Kağıt"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, Lak Kaplama, UV Lak"
        },
        {
          label: "Boyutlar",
          value: "DL (22x11 cm - Diplomat Zarf), C4 (229x324 mm - A4 Sığar), C5 (162x229 mm - A5 Sığar), B4, B5 ve Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Tek Yön / Çift Yön Baskı, Logolu ve Kurumsal Kimlik Tasarımı"
        },
        {
          label: "Kapak Seçenekleri",
          value: "Kendinden Yapışkanlı, Sıvamalı, Telli Kapak, Düğme Kapak"
        },
        {
          label: "Ek Özellikler",
          value: "Pencereli Zarf, Güvenlik Bandı, İç Baskı (Gizlilik İçin)"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kurumsal Yazışmalar ve Evrak Gönderimleri",
        "Resmi Duyuru ve Bildirimler",
        "Promosyon ve Davetiye Gönderimleri",
        "Fuar ve Etkinlik Katılımcı Kitleri",
        "Sertifika, Diploma ve Ödül Sunumları"
      ]
    },
    {
      id: "cd-kabi",
      title: "CD Kabı",
      name: "Kişiselleştirilebilir CD Kabı Tasarımları",
      images: [cdCase, cdCase1, cdCase2],
      description: `
        CD ve DVD'lerinizi güvenli bir şekilde saklamak ve şık bir sunum yapmak için tasarlanan 
        CD kaplarımız, hem kurumsal hem de kişisel kullanım için idealdir. 
        Promosyon, albüm, sunum ve arşivleme ihtiyaçlarına yönelik dayanıklı ve estetik tasarım seçenekleri sunuyoruz.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "300g - 400g Kuşe Karton, PVC, PP (Polipropilen), Karton Kaplama"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Spot Lak"
        },
        {
          label: "Boyutlar",
          value: "Standart CD Kapak (12x12 cm), Slim CD Kapak, Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Tek Yön / Çift Yön Baskı, Logolu ve Tasarımlı Seçenekler"
        },
        {
          label: "Kilit ve Kapanma Seçenekleri",
          value: "Mıknatıslı Kapanma, Düğme Kapak, Yapışkanlı Kapanma"
        },
        {
          label: "Ek Özellikler",
          value: "CD / DVD Tutucu Kıskaç, Kartvizit ve Broşür Cebi, İç Bölmeli Tasarım"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Müzik Albümleri ve Video Prodüksiyonları",
        "Eğitim ve Seminer İçerikleri",
        "Kurumsal Sunum ve Tanıtım Dosyaları",
        "Arşivleme ve Saklama",
        "Hediyelik ve Promosyon Ürünleri"
      ]
    },
    {
      id: "antetli-kagit",
      title: "Antetli Kağıt",
      name: "Kurumsal Kimlik İçin Antetli Kağıt Tasarımları",
      images: [letterhead],
      description: `
        Antetli kağıtlar, kurumsal kimliğinizi güçlendiren ve profesyonel yazışmalarda güven oluşturan 
        önemli bir basılı materyaldir. Firmanızın logosu, iletişim bilgileri ve kurumsal kimlik tasarımıyla 
        özelleştirilen antetli kağıtlar, prestijinizi artırır ve dikkat çekici bir görünüm sağlar.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "80g - 120g 1. Hamur Kağıt, Ivory Kağıt, Geri Dönüşümlü Kağıt"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Tek Renk / Çok Renk, Ön / Arka Baskı"
        },
        {
          label: "Boyutlar",
          value: "A4 (21x29.7 cm - Standart), A5 (14.8x21 cm), Özel Ölçüler"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon (İsteğe Bağlı), UV Lak (Logoda Vurgulama İçin)"
        },
        {
          label: "Ek Özellikler",
          value: "Logolu Tasarım, Özel Kenar Desenleri, Suya Dayanıklı Baskı Seçenekleri"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Resmi Yazışmalar",
        "Sözleşme ve Teklif Belgeleri",
        "Fatura ve İrsaliye Evrakları",
        "Kurumsal Duyurular ve Bildirimler",
        "Müşteri ve İş Ortağı Yazışmaları"
      ]
    },
    {
      id: "kartvizit",
      title: "Kartvizit",
      name: "Profesyonel ve Kişiselleştirilebilir Kartvizit Tasarımları",
      images: [businessCard, businessCard1],
      description: `
        Kartvizit, iş dünyasında ilk izlenim için kritik öneme sahiptir. 
        Profesyonel tasarımlarımız, firmanızın kurumsal kimliğini ön plana çıkarırken,
        kaliteli malzeme ve şık baskı seçenekleriyle prestijinizi artırır. 
        Klasik, modern veya yaratıcı tasarımlarımızla fark yaratın!
      `,
      categories: [JobCategories.PRINTED_PRODUCTS, JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "300g - 400g Kuşe Karton, PVC, Metal, Ahşap, Kraft Kağıt"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Spot Lak, Kabartma Baskı"
        },
        {
          label: "Boyutlar",
          value: "8.5x5.5 cm (Standart), 9x5 cm, Kare veya Özel Kesim"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Altın / Gümüş Yaldız, Kabartma Logo ve Yazı"
        },
        {
          label: "Kenar Seçenekleri",
          value: "Yuvarlak Köşe, Düz Köşe, Özel Kesim Tasarım"
        },
        {
          label: "Ek Özellikler",
          value: "Çift Yön Baskı, QR Kod Ekleme, Vernikli veya Kabartma Logolu Tasarım"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kurumsal Kimlik ve Marka Tanıtımı",
        "Networking ve İş Buluşmaları",
        "Etkinlik ve Fuar Katılımcıları",
        "Kişisel Marka Tanıtımı",
        "Hizmet ve Ürün Tanıtımları"
      ]
    },
    {
      id: "yapiskanli-not-kagidi",
      title: "Yapışkanlı Not Kağıdı",
      name: "Kişiselleştirilebilir Yapışkanlı Not Kağıtları",
      images: [selfStickNote, selfStickNote1],
      description: `
        Yapışkanlı not kağıtları, ofis, eğitim ve ev kullanımı için ideal bir üründür. 
        Pratik yapısı ve şık tasarımıyla işlerinizi kolaylaştırırken, markanızın 
        sürekli göz önünde olmasını sağlar. Farklı boyut ve renk seçenekleriyle 
        kişiselleştirilebilir modellerimiz, kurumsal tanıtımlar ve promosyon kampanyaları için mükemmel bir çözümdür.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "80g - 100g 1. Hamur Kağıt, Geri Dönüşümlü Kağıt"
        },
        {
          label: "Yapışkan Özelliği",
          value: "Kolay Yapışır / Kolay Çıkartılır, Kalıntı Bırakmaz"
        },
        {
          label: "Boyutlar",
          value: "7.5x7.5 cm (Standart), 10x10 cm, 5x7.5 cm veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Tek Renk / Çok Renk, Logo ve Kurumsal Kimlik Tasarımı"
        },
        {
          label: "Sayfa Düzeni",
          value: "Çizgili, Kareli, Düz veya Özel Tasarımlı"
        },
        {
          label: "Ek Özellikler",
          value: "Kapaklı Model, Blok Notluk ile Kombinasyon, Renkli ve Şeffaf Seçenekler"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Ofis ve Kurumsal Ortamlar",
        "Eğitim Kurumları",
        "Ev ve Kişisel Notlar",
        "Promosyon ve Tanıtım Ürünleri",
        "Organizasyon ve Etkinliklerde Hediye"
      ]
    },
    {
      id: "roll-up-banner",
      title: "Roll Up Banner",
      name: "Taşınabilir ve Şık Roll Up Banner Modelleri",
      images: [rollUp],
      description: `
        Roll up banner modellerimiz, fuar, seminer, etkinlik ve tanıtım organizasyonlarında 
        firmanızın dikkat çekici bir şekilde tanıtılmasını sağlar. Hafif, taşınabilir ve kolay kurulabilir yapısıyla
        her ortamda profesyonel görünüm sunar. Dayanıklı malzemesi sayesinde uzun süreli kullanım sağlar.
      `,
      categories: [JobCategories.PRINTED_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Vinil, Branda, PP (Polipropilen) Film, Lamine Kaplama"
        },
        {
          label: "Boyutlar",
          value: "85x200 cm (Standart), 100x200 cm, 120x200 cm veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Fotoğraf Kalitesinde Dijital Baskı"
        },
        {
          label: "Mekanizma",
          value: "Alüminyum Profil, Dayanıklı Taban, Tek Yön / Çift Yön Seçenekleri"
        },
        {
          label: "Ek Özellikler",
          value: "Taşıma Çantası, Kolay Kurulum Mekanizması, Değiştirilebilir Görsel Alanı"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 3-5 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Fuar ve Sergi Alanları",
        "Seminer ve Konferanslar",
        "Mağaza ve AVM İçerisinde Ürün Tanıtımları",
        "Restoran ve Kafe Menü Tanıtımları",
        "Kurumsal Organizasyon ve Etkinliklerde"
      ]
    },
    {
      id: "karton-poset",
      title: "Karton Poşet",
      name: "Şık ve Dayanıklı Karton Poşet Modelleri",
      images: [paperBag, paperBag1, paperBag2, paperBag3],
      description: `
        Karton poşet modellerimiz, estetik ve dayanıklı yapısıyla alışveriş, promosyon ve kurumsal etkinliklerde 
        markanızı prestijli bir şekilde tanıtmanıza olanak sağlar. Farklı boyut ve tasarım seçenekleriyle 
        her sektöre uygun çözümler sunuyoruz.
      `,
      categories: [JobCategories.PACKAGING_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "170g - 250g Kraft Kağıt, Kuşe Kağıt, Bristol Karton"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, UV Lak, Kabartma Baskı"
        },
        {
          label: "Boyutlar",
          value: "Küçük (20x25x10 cm), Orta (30x40x12 cm), Büyük (40x50x15 cm) veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Metalik Yaldız, Kabartma ve Laklı Baskı"
        },
        {
          label: "Sap Seçenekleri",
          value: "Pamuk İp, Kurdele, Kâğıt Burgulu Sap, Plastik Sap"
        },
        {
          label: "Ek Özellikler",
          value: "Logolu Tasarım, İç ve Dış Renk Seçenekleri, Özel Kapak ve Güçlendirilmiş Taban"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-10 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Mağaza ve Butik Satışları",
        "Fuar ve Etkinlik Hediyelikleri",
        "Kurumsal Etkinlik ve Promosyon Ürünleri",
        "Lüks Alışveriş Mağazaları",
        "Restoran ve Kafe Paket Servisleri"
      ]
    },
    {
      id: "karton-bardak",
      title: "Karton Bardak",
      name: "Kişiselleştirilebilir ve Dayanıklı Karton Bardaklar",
      images: [paperCup, paperCup1, paperCup2],
      description: `
        Karton bardaklarımız, kafe, restoran, etkinlik ve ofislerde sıcak ve soğuk içecekler için ideal çözümler sunar.
        Şık tasarım ve kişiselleştirilebilir baskı seçenekleriyle markanızı ön plana çıkarırken, çevre dostu malzemelerle üretilen modellerimiz sürdürülebilirliğe katkı sağlar.
      `,
      categories: [JobCategories.PACKAGING_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Tek Katmanlı (Single Wall) veya Çift Katmanlı (Double Wall) Karton"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "İçten PE (Polietilen) Kaplama, PLA (Biyobozunur) Kaplama"
        },
        {
          label: "Boyutlar",
          value: "4 oz (110 ml), 8 oz (230 ml), 12 oz (355 ml), 16 oz (470 ml)"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Tam Renkli Fotoğraf Kalitesinde Baskı, Logolu Tasarım"
        },
        {
          label: "Kapak Seçenekleri",
          value: "Plastik Kapak (Siyah / Beyaz), Biyobozunur Kapak Seçenekleri"
        },
        {
          label: "Ek Özellikler",
          value: "Dökülme Önleyici Kapak, Isı Yalıtımı İçin Ek Katman, Geri Dönüştürülebilir Malzeme"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kafe ve Restoranlar",
        "Ofis ve İş Yerleri",
        "Fuar ve Etkinlik Organizasyonları",
        "Sokak Lezzetleri ve Mobil Satış Noktaları",
        "Oteller ve Konaklama Tesisleri"
      ]
    },
    {
      id: "kargo-kutusu",
      title: "Kargo Kutusu",
      name: "Dayanıklı ve Kişiselleştirilebilir Kargo Kutuları",
      images: [cargoBox, cargoBox1, cargoBox2, cargoBox3, cargoBox4],
      description: `
        Kargo kutularımız, e-ticaret, depo ve lojistik alanlarında ürünlerinizi güvenle taşımak için ideal çözümler sunar.
        Sağlam yapısı ve kişiselleştirilebilir tasarımıyla ürünlerinizi korurken aynı zamanda markanızı ön plana çıkarır.
        Özel kesim, farklı boyut ve baskı seçenekleriyle her ihtiyaca uygun tasarımlar üretiyoruz.
      `,
      categories: [JobCategories.PACKAGING_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "E Dalga Mukavva, B Dalga Mukavva, Kraft Karton, Çift Katmanlı Karton"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, Kraft Doku, Suya Dayanıklı Kaplama"
        },
        {
          label: "Boyutlar",
          value: "Küçük (20x20x10 cm), Orta (30x30x20 cm), Büyük (40x40x30 cm) veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Logolu ve Özel Desenli Baskı"
        },
        {
          label: "Kilit ve Kapatma Seçenekleri",
          value: "Kendinden Yapışkanlı Kapak, Koli Bandı İle Kapatma, Tırnaklı Kilit Sistemi"
        },
        {
          label: "Ek Özellikler",
          value: "Elde Taşıma Delikleri, Güçlendirilmiş Taban, Çift Katmanlı Yan Duvarlar"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-10 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "E-Ticaret Gönderimleri",
        "Depo ve Stoklama",
        "Kurumsal Hediye Paketlemeleri",
        "Kırılabilir ve Değerli Ürün Gönderimleri",
        "Tekstil ve Kozmetik Ürün Paketlemeleri"
      ]
    },
    {
      id: "gida-ambalaji",
      title: "Gıda Ambalajı",
      name: "Hijyenik ve Dayanıklı Gıda Ambalajı Çözümleri",
      images: [foodPackaging, foodPackaging1, foodPackaging2, foodPackaging3, foodPackaging4, foodPackaging5],
      description: `
        Gıda ambalajlarımız, ürünlerinizin tazeliğini ve hijyenini korumak için özel olarak tasarlanmıştır.
        Restoran, kafe, pastane ve gıda üreticileri için dayanıklı, sızdırmaz ve çevre dostu ambalaj çözümleri sunuyoruz.
        Şık tasarım ve kişiselleştirilebilir baskı seçenekleriyle markanızı ön plana çıkarabilirsiniz.
      `,
      categories: [JobCategories.PACKAGING_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Kraft Kağıt, PET, PP (Polipropilen), Alüminyum Kaplama, Bio-kompost Malzemeler"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Suya Dayanıklı, Yağa Dayanıklı, UV Lak, Mat / Parlak Selefon"
        },
        {
          label: "Boyutlar",
          value: "Küçük (15x15 cm), Orta (20x20 cm), Büyük (30x30 cm) veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Gıda Temasına Uygun Özel Mürekkep"
        },
        {
          label: "Kapatma ve Sızdırmazlık",
          value: "Yapışkanlı Kapak, Kilitli Kapak, Katlanabilir Kapatma Sistemi"
        },
        {
          label: "Ek Özellikler",
          value: "Havalandırma Delikleri, Çevre Dostu ve Geri Dönüştürülebilir Malzemeler, Şeffaf Pencere Seçenekleri"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-10 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Restoran ve Kafeler",
        "Pastane ve Fırın Ürünleri",
        "Fast Food ve Paket Servis Ürünleri",
        "Organik ve Sağlıklı Ürün Satışları",
        "Etkinlik ve Fuar Gıda Standları"
      ]
    },
    {
      id: "anahtarlik",
      title: "Anahtarlık",
      name: "Kişiselleştirilebilir ve Dayanıklı Anahtarlık Modelleri",
      images: [keychain],
      description: `
        Anahtarlıklar, promosyon ürünleri, kurumsal hediyeler ve kişisel kullanımlar için 
        popüler ve etkili bir tanıtım aracıdır. Şık tasarımı ve dayanıklı yapısıyla 
        markanızı her zaman göz önünde tutan anahtarlık modellerimiz, farklı malzeme ve tasarım seçenekleriyle sunulmaktadır.
      `,
      categories: [JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Metal, Deri, PVC, Akrilik, Ahşap, Silikon"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Parlak / Mat Kaplama, Krom / Nikel Kaplama, Antik Doku"
        },
        {
          label: "Boyutlar",
          value: "5x5 cm, 6x4 cm veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "Lazer Baskı, UV Baskı, Serigrafi, Kabartma Logo, Renkli Döküm"
        },
        {
          label: "Ek Özellikler",
          value: "Işıklı, Açacaklı, Pusulalı, USB Bellek Entegreli, Logolu Plaka"
        },
        {
          label: "Ambalaj Seçenekleri",
          value: "Şık Hediye Kutusu, Tekli Poşet Ambalajı, Kurumsal Paketleme"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kurumsal Promosyon Ürünleri",
        "Hediye ve Hatıra Eşyaları",
        "Fuar ve Etkinlik Dağıtım Ürünleri",
        "Araç Anahtarlıkları ve Logo Tanıtımları",
        "Kafe, Restoran ve Otel Promosyonları"
      ]
    },
    {
      id: "bez-canta",
      title: "Bez Çanta",
      name: "Doğa Dostu ve Kişiselleştirilebilir Bez Çantalar",
      images: [bag],
      description: `
        Bez çantalar, çevre dostu yapısı ve şık tasarımıyla alışveriş, etkinlik ve promosyonlarda 
        sıkça tercih edilen bir üründür. Geri dönüştürülebilir malzemelerden üretilen modellerimiz, 
        dayanıklı yapısı sayesinde uzun ömürlü kullanım sağlar. Kişiselleştirilebilir tasarımlarımızla markanızı ön plana çıkarabilirsiniz.
      `,
      categories: [JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Pamuklu Kumaş, Non-Woven, Kanvas, Jüt Kumaş"
        },
        {
          label: "Gramaj",
          value: "110g - 340g arası farklı kalınlık seçenekleri"
        },
        {
          label: "Boyutlar",
          value: "30x35 cm, 40x45 cm veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "Serigrafi Baskı, Dijital Baskı, Transfer Baskı, Kalıcı Boyama"
        },
        {
          label: "Sap Seçenekleri",
          value: "Kısa Sap, Uzun Sap, Omuz Askılı, Elde Taşıma Sapı"
        },
        {
          label: "Ek Özellikler",
          value: "Kapatma İpli, Fermuarlı, İç Cepli, Katlanabilir Tasarım"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 7-10 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Alışveriş ve Market Çantaları",
        "Etkinlik ve Fuar Hediyeleri",
        "Kafe ve Restoran Promosyonları",
        "Kurumlar İçin Çevre Dostu Hediye",
        "Eğitim ve Kırtasiye Ürünleri"
      ]
    },
    {
      id: "kupa-bardak",
      title: "Kupa Bardak",
      name: "Kişiselleştirilebilir ve Şık Kupa Bardak Modelleri",
      images: [mug],
      description: `
        Kupa bardaklar, ofis, ev ve etkinliklerde en çok tercih edilen promosyon ve hediye ürünlerinden biridir.
        Farklı tasarım ve baskı seçenekleriyle, markanızı her yudumda hatırlatacak kişiselleştirilebilir modeller sunuyoruz.
        Seramikten metale kadar geniş ürün yelpazemizle estetik ve işlevsellik bir arada!
      `,
      categories: [JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Seramik, Porselen, Cam, Metal, Emaye"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Kaplama, Renk Değiştiren (Sihirli) Kaplama"
        },
        {
          label: "Boyutlar",
          value: "250 ml, 330 ml (Standart), 450 ml veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "Sublimasyon Baskı, UV Baskı, Lazer Kazıma, Tam Renk Dijital Baskı"
        },
        {
          label: "Renk Seçenekleri",
          value: "Beyaz, Siyah, Renkli İç ve Sap Seçenekleri"
        },
        {
          label: "Ek Özellikler",
          value: "Kapasite Ölçü İşaretli, Kapaklı ve Karıştırıcılı Modeller, Isıya Duyarlı Tasarım"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-10 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Ofis ve Ev Kullanımı",
        "Fuar ve Etkinlik Promosyonları",
        "Kurumsal Hediye ve Tanıtım Ürünleri",
        "Kafe ve Restoranlar İçin Özel Tasarımlar",
        "Doğum Günü ve Özel Gün Hediyeleri"
      ]
    },
    {
      id: "bardak-tasiyici",
      title: "Bardak Taşıyıcı",
      name: "Fonksiyonel ve Şık Bardak Tutucu Modelleri",
      images: [cupHolder, cupHolder1, cupHolder2],
      description: `
        Bardak taşıyıcılar, sıcak ve soğuk içeceklerin daha rahat taşınmasını sağlayan 
        işlevsel ve estetik bir üründür. Özellikle kafe, restoran ve etkinliklerde marka bilinirliğini artırmak için 
        mükemmel bir promosyon ürünüdür. Kişiselleştirilebilir tasarımlarımızla markanızı her yudumda öne çıkarın!
      `,
      categories: [JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Kraft Karton, Kuşe Karton, Lamine Kaplama, Keçe Kumaş, Silikon"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Mat / Parlak Selefon, Suya ve Isıya Dayanıklı Kaplama"
        },
        {
          label: "Boyutlar",
          value: "S (250 ml), M (330 ml), L (450 ml) veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "CMYK, Pantone, Tam Renk Dijital Baskı, Logolu Tasarım"
        },
        {
          label: "Renk Seçenekleri",
          value: "Doğal Kraft, Beyaz, Siyah veya Özel Renk Seçenekleri"
        },
        {
          label: "Ek Özellikler",
          value: "Çift Katmanlı Yalıtım, Elden Kaymayan Tutuş Yüzeyi, Çevre Dostu ve Geri Dönüştürülebilir Malzeme"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kafe ve Restoranlar",
        "Fuar ve Etkinliklerde Promosyon Ürünü",
        "Ofis ve İş Yeri Kullanımı",
        "Paket Servis Hizmetleri",
        "Marka Bilinirliği Kampanyaları"
      ]
    },
    {
      id: "rozet",
      title: "Rozet",
      name: "Kişiselleştirilebilir ve Şık Rozet Modelleri",
      images: [badge],
      description: `
        Rozetler, etkinliklerde, organizasyonlarda ve promosyon kampanyalarında sıkça tercih edilen 
        etkili tanıtım araçlarıdır. Farklı malzeme ve tasarım seçenekleriyle hazırlanan rozet modellerimiz,
        firmanızın logosunu, etkinliğinizi veya mesajınızı en etkili şekilde tanıtmanızı sağlar.
      `,
      categories: [JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Metal, Plastik, PVC, Ahşap, Akrilik"
        },
        {
          label: "Kaplama Seçenekleri",
          value: "Parlak / Mat Kaplama, Krom / Nikel Kaplama, Antik Doku"
        },
        {
          label: "Boyutlar",
          value: "25 mm, 37 mm, 58 mm, 75 mm veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "Dijital Baskı, UV Baskı, Serigrafi, Kabartma Logo"
        },
        {
          label: "Ek Özellikler",
          value: "İğneli Arka Kısmı, Mıknatıslı, Klipsli veya Rozet İğneli Seçenekler"
        },
        {
          label: "Ambalaj Seçenekleri",
          value: "Şık Hediye Kutusu, Tekli Poşet Ambalajı, Kurumsal Paketleme"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kurumsal Etkinlik ve Organizasyonlar",
        "Fuar ve Tanıtım Kampanyaları",
        "Mezuniyet ve Ödül Törenleri",
        "Okul ve Kulüp Etkinlikleri",
        "Promosyon ve Hediyelik Ürünler"
      ]
    },
    {
      id: "t-shirt",
      title: "T-shirt",
      name: "Kişiselleştirilebilir ve Şık T-shirt Modelleri",
      images: [tshirt],
      description: `
        T-shirt modellerimiz, kurumsal tanıtım, etkinlik organizasyonları ve promosyon kampanyaları için 
        ideal bir çözümdür. Yüksek kaliteli kumaş seçenekleri ve özelleştirilebilir tasarımlarla markanızı en etkili şekilde tanıtabilirsiniz. 
        Geniş renk ve kesim seçenekleriyle her ihtiyaca uygun modeller sunuyoruz.
      `,
      categories: [JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Pamuk, Polyester, Organik Pamuk, Dry Fit Kumaş"
        },
        {
          label: "Kesim Seçenekleri",
          value: "Regular Fit, Slim Fit, Oversize, Polo Yaka"
        },
        {
          label: "Beden Seçenekleri",
          value: "XS, S, M, L, XL, XXL"
        },
        {
          label: "Baskı Seçenekleri",
          value: "Serigrafi Baskı, Dijital Baskı, Nakış İşleme, Transfer Baskı"
        },
        {
          label: "Renk Seçenekleri",
          value: "Beyaz, Siyah, Gri, Kırmızı, Mavi ve Özel Renk Seçenekleri"
        },
        {
          label: "Ek Özellikler",
          value: "Kol ve Yaka Logosu, Etiket Baskı, Çevre Dostu ve Nefes Alabilir Kumaş"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 7-10 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Fuar ve Etkinlik Promosyonları",
        "Kurumsal Kimlik ve Marka Tanıtımı",
        "Spor Etkinlikleri ve Takım Kıyafetleri",
        "Kampanya ve Reklam Çalışmaları",
        "Özel Gün Hediyeleri ve Kişisel Tasarımlar"
      ]
    },
    {
      id: "sapka",
      title: "Şapka",
      name: "Kişiselleştirilebilir ve Şık Şapka Modelleri",
      images: [cap],
      description: `
        Şapka modellerimiz, kurumsal etkinliklerde, promosyon kampanyalarında ve günlük kullanımlarda 
        şıklığı ve işlevselliği bir araya getirir. Yüksek kaliteli kumaş seçenekleri ve kişiselleştirilebilir tasarımlarla 
        markanızı en etkili şekilde tanıtabilirsiniz.
      `,
      categories: [JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Pamuk, Polyester, Kanvas, Jüt Kumaş, Yıkanmış Kot Kumaş"
        },
        {
          label: "Model Seçenekleri",
          value: "Düz Kep, 5 Panel Şapka, Snapback, Trucker Şapka, Balıkçı Şapka"
        },
        {
          label: "Beden Seçenekleri",
          value: "Ayarlanabilir Klipsli, Cırt Cırtlı veya Lastikli"
        },
        {
          label: "Baskı Seçenekleri",
          value: "Nakış İşleme, Serigrafi Baskı, UV Baskı, Dijital Baskı, Deri Etiket"
        },
        {
          label: "Renk Seçenekleri",
          value: "Beyaz, Siyah, Kırmızı, Mavi, Yeşil ve Özel Renk Seçenekleri"
        },
        {
          label: "Ek Özellikler",
          value: "Fileli Arka Tasarım, Güneş Koruyucu Geniş Siperlik, Logolu Yama ve Metal Aksesuarlar"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 7-10 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Fuar ve Etkinlik Promosyonları",
        "Kurumsal Kimlik ve Marka Tanıtımı",
        "Spor Etkinlikleri ve Takım Kıyafetleri",
        "Outdoor ve Kamp Etkinlikleri",
        "Hediye ve Kişisel Tasarımlar"
      ]
    },
    {
      id: "kalem",
      title: "Kalem",
      name: "Kişiselleştirilebilir ve Şık Kalem Modelleri",
      images: [pen],
      description: `
        Kalem modellerimiz, kurumsal etkinliklerde, promosyon kampanyalarında ve günlük kullanımlarda 
        şıklığı ve işlevselliği bir araya getirir. Metal, plastik ve bambu gibi geniş malzeme yelpazemiz 
        ve kişiselleştirilebilir tasarım seçenekleriyle markanızı her zaman göz önünde tutabilirsiniz.
      `,
      categories: [JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Metal, Plastik, Bambu, Ahşap, Alüminyum, Geri Dönüştürülmüş Malzeme"
        },
        {
          label: "Mekanizma Seçenekleri",
          value: "Çevirmeli, Basmalı, Kapaklı, Tükenmez, Roller ve Dolma Kalem Modelleri"
        },
        {
          label: "Baskı Seçenekleri",
          value: "Lazer Kazıma, UV Baskı, Serigrafi Baskı, Tam Renk Dijital Baskı"
        },
        {
          label: "Renk Seçenekleri",
          value: "Siyah, Gümüş, Altın, Kırmızı, Mavi ve Özel Renk Seçenekleri"
        },
        {
          label: "Ek Özellikler",
          value: "Touch Pen (Dokunmatik Ekran Uyumlu), Işıklı Logo, USB Bellek Entegreli Modeller"
        },
        {
          label: "Ambalaj Seçenekleri",
          value: "Tekli Kutu, Set Halinde Hediye Paketi, Şık Kurumsal Paketleme"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kurumsal Promosyon Ürünleri",
        "Eğitim ve Kırtasiye Ürünleri",
        "Fuar ve Etkinlik Tanıtımları",
        "Ofis ve İş Yerleri",
        "Özel Gün Hediyeleri ve Kişisel Tasarımlar"
      ]
    },
    {
      id: "powerbank",
      title: "Powerbank",
      name: "Kişiselleştirilebilir ve Yüksek Kapasiteli Powerbank Modelleri",
      images: [powerbank, powerbank1],
      description: `
        Powerbank modellerimiz, mobil cihazların şarj ihtiyacını karşılayan pratik ve işlevsel bir üründür.
        Kişiselleştirilebilir tasarım seçenekleriyle markanızı öne çıkararak fuar, etkinlik ve kurumsal promosyonlarda 
        etkili bir tanıtım aracı sunar. Farklı kapasite ve tasarım seçenekleriyle her ihtiyaca uygun çözümler üretiyoruz.
      `,
      categories: [JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Metal, Plastik, Alüminyum, Deri Kaplama, Bambu"
        },
        {
          label: "Kapasite Seçenekleri",
          value: "2.500 mAh, 5.000 mAh, 10.000 mAh, 20.000 mAh"
        },
        {
          label: "Bağlantı Seçenekleri",
          value: "USB-A, USB-C, Micro USB, Lightning Kablolu Modeller"
        },
        {
          label: "Baskı Seçenekleri",
          value: "UV Baskı, Lazer Kazıma, Tam Renk Dijital Baskı, Logolu Tasarım"
        },
        {
          label: "Ek Özellikler",
          value: "LED Ekranlı, Kablosuz Şarj Özelliği, Hızlı Şarj Desteği (PD/QC), Çoklu Çıkış Portları"
        },
        {
          label: "Ambalaj Seçenekleri",
          value: "Özel Kutu, Şık Hediye Paketi, Kurumsal Paketleme"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Fuar ve Etkinlik Promosyonları",
        "Kurumsal Hediye ve Tanıtım Ürünleri",
        "Teknoloji ve Elektronik Mağazaları",
        "Outdoor ve Seyahat Ürünleri",
        "Özel Gün ve Organizasyon Hediyeleri"
      ]
    },
    {
      id: "usb-bellek",
      title: "USB Bellek",
      name: "Kişiselleştirilebilir ve Şık USB Bellek Modelleri",
      images: [usb],
      description: `
        USB bellek modellerimiz, veri depolama ve taşıma ihtiyaçları için işlevsel ve pratik bir çözümdür.
        Kurumsal promosyonlarda, etkinliklerde ve özel günlerde kişiselleştirilebilir tasarım seçenekleriyle 
        markanızı ön plana çıkarmanızı sağlar. Farklı kapasite ve tasarım alternatifleriyle geniş ürün yelpazemizle hizmetinizdeyiz.
      `,
      categories: [JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Metal, Plastik, Ahşap, Deri Kaplama, Bambu"
        },
        {
          label: "Kapasite Seçenekleri",
          value: "4 GB, 8 GB, 16 GB, 32 GB, 64 GB, 128 GB"
        },
        {
          label: "Bağlantı Seçenekleri",
          value: "USB 2.0, USB 3.0, USB-C, Çift Taraflı (USB-A & USB-C)"
        },
        {
          label: "Baskı Seçenekleri",
          value: "Lazer Kazıma, UV Baskı, Tam Renk Dijital Baskı, Logolu Tasarım"
        },
        {
          label: "Ek Özellikler",
          value: "Döner Kapaklı, Manyetik Kapaklı, Anahtarlık Halkalı, Kart Tipi USB"
        },
        {
          label: "Ambalaj Seçenekleri",
          value: "Özel Kutu, Hediye Paketi, Kurumsal Paketleme"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Kurumsal Promosyon Ürünleri",
        "Fuar ve Etkinlik Tanıtımları",
        "Veri Depolama ve Arşivleme",
        "Teknoloji ve Elektronik Mağazaları",
        "Özel Gün ve Organizasyon Hediyeleri"
      ]
    },
    {
      id: "mouse-pad",
      title: "Mouse Pad",
      name: "Kişiselleştirilebilir ve Şık Mouse Pad Modelleri",
      images: [mousepad],
      description: `
        Mouse pad modellerimiz, hem konforlu kullanım sağlayan yapısıyla hem de kişiselleştirilebilir tasarımlarıyla 
        masa başı çalışanlar için vazgeçilmezdir. Şık tasarım ve logolu baskı seçenekleriyle markanızı sürekli göz önünde 
        tutabileceğiniz etkili bir promosyon ürünüdür.
      `,
      categories: [JobCategories.PROMOTIONAL_PRODUCTS],
      specs: [
        {
          label: "Malzeme",
          value: "Kauçuk Tabanlı Kumaş, Jel Desteği, Deri Kaplama, Sert PVC Yüzey"
        },
        {
          label: "Boyutlar",
          value: "22x18 cm (Standart), 25x20 cm, 30x25 cm veya Özel Ölçüler"
        },
        {
          label: "Baskı Seçenekleri",
          value: "Sublimasyon Baskı, UV Baskı, Serigrafi, Tam Renk Dijital Baskı"
        },
        {
          label: "Ek Özellikler",
          value: "Kaymaz Taban, Bilek Desteği, RGB Aydınlatmalı, Oyun (Gaming) Modeli"
        },
        {
          label: "Ambalaj Seçenekleri",
          value: "Tekli Kutu, Kurumsal Paketleme, Hediye Poşeti"
        },
        {
          label: "Teslimat Süresi",
          value: "Ortalama 5-7 iş günü (Sipariş miktarına ve tasarım detaylarına bağlı olarak değişebilir)"
        }
      ],
      usageAreas: [
        "Ofis ve İş Yeri Kullanımı",
        "Fuar ve Etkinlik Promosyonları",
        "Kurumsal Hediye ve Tanıtım Ürünleri",
        "Oyun ve E-Spor Kullanıcıları",
        "Eğitim Kurumları ve Kırtasiye Ürünleri"
      ]
    }
  ],
};

export default productsData;
