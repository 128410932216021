const faqData = {
  title: "Sıkça Sorulan Sorular",
  description: "Matbaa ve baskı süreçleri hakkında en çok merak edilen soruların cevaplarını aşağıda bulabilirsiniz. Daha fazla bilgi almak veya özel taleplerinizi iletmek için bizimle iletişime geçmekten çekinmeyin.",
  faqs: [
    {
  question: "Matbaa süreci nasıl işler?",
  answer: `Matbaa sürecimiz siparişten teslimata kadar titizlikle yürütülür:

1. **Sipariş Onayı:** Siparişiniz alındıktan sonra detaylar ekibimiz tarafından incelenir ve süreç başlatılır.
2. **Tasarım ve Revizyon:** Tasarım ekibimiz taleplerinize göre çalışmayı hazırlar ve onayınıza sunar. Gerekirse revize edilir.
3. **Baskı Hazırlığı:** Onaylanan tasarım teknik kontrollerden geçirilerek baskıya hazır hale getirilir.
4. **Baskı Süreci:** Ürünler, proje türüne göre en uygun baskı tekniğiyle yüksek kalitede basılır.
5. **Kesim, Katlama ve Özel Uygulamalar:** Gerekli durumlarda kesim, katlama, selefon, lak, gofre gibi işlemler uygulanır.
6. **Kalite Kontrol:** Ürünler kalite kontrol ekibimiz tarafından dikkatle incelenir.
7. **Paketleme ve Teslimat:** Kontrolden geçen ürünler özenle paketlenir ve **Aras Kargo** ile adresinize teslim edilir.

Sürecin her aşamasında sizi bilgilendirerek, sorunsuz ve kaliteli bir hizmet sunmayı hedefliyoruz.`
}
    ,
    {
      question: "Minimum sipariş miktarı nedir?",
      answer: `Tüm ürünlerimizde minimum sipariş adedi **250 adet**tir. 
    Üretim süreçlerimiz toplu siparişlere göre optimize edildiğinden, **sipariş adedi arttıkça birim maliyetlerde düşüş** sağlanmaktadır. 
    Bu sayede yüksek hacimli siparişlerde daha avantajlı fiyatlar sunabilmekteyiz. 
    Projenize özel fiyatlandırma için bizimle iletişime geçebilirsiniz.`
    },
    {
      question: "Teslim süresi ne kadar?",
      answer: `Teslimat süremiz siparişin türüne ve hacmine göre değişiklik gösterebilir:
- Standart siparişlerde **5-7 iş günü**.
- Özel tasarım ve büyük hacimli siparişlerde teslimat süresi projeye göre belirlenmektedir.
Acil siparişleriniz için ekspres hizmetimiz mevcuttur.`
    },
    {
      question: "Hangi baskı tekniklerini kullanıyorsunuz?",
      answer: `Medya Ambalaj olarak geniş bir baskı teknolojisi yelpazesi sunmaktayız:
- **Ofset Baskı:** Yüksek hacimli ve kaliteli baskılar için idealdir.
- **Dijital Baskı:** Küçük adetli ve hızlı çözümler için uygundur.
- **Serigrafi Baskı:** Özellikle tekstil ve promosyon ürünlerinde kullanılır.
- **UV Baskı:** Yüksek dayanıklılık gerektiren yüzeylerde tercih edilir.
İhtiyacınıza uygun en ideal baskı tekniği için ekibimiz size memnuniyetle yardımcı olacaktır.`
    },
    {
      question: "Özel tasarım sipariş verebilir miyim?",
      answer: `Evet, profesyonel tasarım ekibimiz özel projeleriniz için size en uygun çözümleri üretir. Markanızı en iyi şekilde yansıtan özgün tasarımlar için bizimle iletişime geçebilirsiniz.`
    },
    {
      question: "Baskı dosyası hangi formatlarda olmalı?",
      answer: `Tasarım dosyalarınızı yüksek çözünürlükte ve baskıya uygun formatlarda hazırlamanız gerekmektedir. Tercih ettiğimiz dosya formatları şunlardır:
- **PDF (Tercih edilen format)**
- **AI (Adobe Illustrator)**
Tasarımınızın baskı kalitesini artırmak için en az 300 DPI çözünürlükte ve CMYK renk formatında hazırlanmış olması önemlidir.`
    },
    {
      question: "Kargo hizmetiniz var mı?",
      answer: `Evet, Türkiye genelinde kargo hizmetimiz bulunmaktadır. **Aras Kargo ile olan anlaşmamız** sayesinde, siparişleriniz güvenli ve hızlı bir şekilde adresinize ulaştırılır.  
    Ürünleriniz üretim sonrası özenle paketlenir ve sevkiyata hazır hale getirilir.  
    Ayrıca, uluslararası gönderimler için de çözüm ortaklarımız aracılığıyla teslimat sağlamaktayız.`
    },
    {
      question: "Sipariş sonrası iptal veya değişiklik yapabilir miyim?",
      answer: `Siparişinizi onayladıktan sonra üretim süreci başladığı için iptal veya değişiklik talepleri sınırlı bir süre içinde yapılabilir. Tasarım aşamasında iptal veya değişiklik mümkündür; ancak baskı aşaması başladıktan sonra bu tür talepler kabul edilememektedir.`
    },
    {
      question: "Ödeme yöntemleriniz nelerdir?",
      answer: `Siparişlerinizde çeşitli ödeme yöntemleriyle kolaylık sağlıyoruz:
- **Banka Havalesi/EFT:** Sipariş sonrası tarafınıza iletilen banka bilgilerimize ödeme yapabilirsiniz.
- **Kredi Kartı:** Güvenli ödeme altyapımız ile kredi kartı ile kolayca ödeme yapabilirsiniz.
- **Nakit Ödeme:** Teslimat sırasında nakit ödeme seçeneğimizden faydalanabilirsiniz.

**Ödeme Politikamız:**  
- Sipariş onayı sonrası toplam ücretin **%30'u** ön ödeme olarak alınır.  
- Üretim tamamlandıktan ve ürünleriniz teslimata hazır olduğunda kalan **%70'lik tutar** talep edilir.  
Bu yöntem, her iki tarafın da güvenliğini sağlamak amacıyla uygulanmaktadır.`
    }
  ]
};

export default faqData;
