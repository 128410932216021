const commentsData = {
  subTitle: "Müşterilerimizin Memnuniyeti Bizim İçin Öncelik;",
  comments: [
    {
      id: 1,
      name: 'Serkan Koyun',
      rating: '★★★★★',
      comment: 'Sipariş verdiğim ürün tam zamanında geldi ve kalitesi beklentimin çok üzerindeydi. Uzun süredir çalıştığım en güvenilir firma diyebilirim.'
    },
    {
      id: 2,
      name: 'Ayşe Yılmaz',
      rating: '★★★★☆',
      comment: 'Mehmet Bey son derece ilgiliydi ve çok yardımcı oldular. Ürün kalitesi çok iyi, ancak kargo biraz gecikti.'
    },
    {
      id: 3,
      name: 'Murat Çelik',
      rating: '★★★★★',
      comment: 'Özellikle ambalajlama konusunda gerçekten özenli bir iş çıkarmışlar. Ürünler eksiksiz ve zarar görmeden elime ulaştı.'
    },
    {
      id: 4,
      name: 'Gizem Toprak',
      rating: '★★★★★',
      comment: 'İlk kez alışveriş yaptım ve çok memnun kaldım. Hem fiyatlar uygun hem de ürün kalitesi mükemmel. Kesinlikle tekrar sipariş vereceğim.'
    },
    {
      id: 5,
      name: 'Fatih Kocaman',
      rating: '★★★★☆',
      comment: 'Ürün kalitesi harika, özellikle renk baskıları çok canlı ve netti. Tek eksiğim teslimatın planlanan tarihten bir gün sonra gelmesiydi.'
    },
    {
      id: 6,
      name: 'Elif Aydın',
      rating: '★★★★★',
      comment: 'Yüksek adetli sipariş vermeme rağmen her şey mükemmel paketlenmişti. Ürünler tam istediğim gibi geldi, çok memnun kaldım.'
    },
    {
      id: 7,
      name: 'Emre Demir',
      rating: '★★★★★',
      comment: 'Kendilerine acil bir sipariş verdim ve söz verdikleri tarihte teslim ettiler. Hem profesyonel hem de samimi bir firma.'
    },
    {
      id: 8,
      name: 'Derya Koç',
      rating: '★★★★★',
      comment: 'Daha önce farklı firmalarla yaşadığım sorunlardan sonra buradan sipariş verdim ve sonuçtan çok memnun kaldım. Tavsiye ederim!'
    },
    {
      id: 9,
      name: 'Mehmet Karakuş',
      rating: '★★★★☆',
      comment: 'Ürünler gerçekten kaliteli ve beklentimi fazlasıyla karşıladı. Kargo süresi biraz uzun sürdü ama sonuca kesinlikle değdi.'
    },
    {
      id: 10,
      name: 'Zeynep Öztürk',
      rating: '★★★★★',
      comment: 'Kaliteli hizmet ve samimi müşteri desteği... Tüm ekip arkadaşlarına teşekkür ederim.'
    }
  ]
};

export default commentsData;
