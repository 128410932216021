import whoAreWePhoto from "../../assets/images/whoarewe.webp"; 

const aboutData = {
    title: "Hakkımızda",
    subtitle: "25 Yıllık Deneyim ve Yenilikçi Çözümlerle Ambalaj ve Baskıda Lider",
    shortContent: [
      {
        heading: "Kalite ve Güven",
        text: "Medya Ambalaj olarak 25 yılı aşkın tecrübemizle, her projede kaliteyi ve güveni önceliklendiriyoruz. Müşteri odaklı yaklaşımımız ve güçlü iş ortaklıklarımızla sektörde fark yaratıyoruz."
      },
      {
        heading: "Yenilikçi Üretim Teknolojisi",
        text: "En son teknoloji üretim makinelerimiz ve uzman ekibimizle, müşterilerimize yenilikçi ve yüksek kaliteli çözümler sunuyoruz. Tasarımdan üretime kadar tüm süreçlerde mükemmeliyeti hedefliyoruz."
      },
      {
        heading: "Müşteri Memnuniyeti",
        text: "Müşteri memnuniyeti, işimizin temelini oluşturur. Projenizin her aşamasında sizinle iş birliği yaparak, beklentilerinizi en üst düzeyde karşılamak için çalışıyoruz."
      }
    ],
    longContent: {
      whoAreWe: {
        title: "Biz Kimiz?",
        description: `Medya Ambalaj, 2000 yılından bu yana ambalaj ve baskı sektöründe kaliteli ve ekonomik çözümler sunan öncü bir kuruluştur.
Küçük bir atölye olarak başladığımız bu yolculukta, müşteri odaklı yaklaşımımız ve kalite anlayışımız sayesinde sektörde önemli bir konuma ulaştık.
Günümüzde geniş makine parkurumuz ve deneyimli ekibimizle Türkiye ve dünya genelindeki markalara güvenilir iş ortağı olarak hizmet veriyoruz.`,
        image: whoAreWePhoto,
        backgroundColor: "primary.main",
        textColor: "secondary",
        imagePosition: "right",
      },
      vision: {
        title: "Vizyonumuz",
        description: `Medya Ambalaj olarak vizyonumuz, baskı ve ambalaj sektöründe yenilikçi teknolojilerle lider konuma ulaşmak ve sürdürülebilir üretim anlayışıyla sektöre yön vermektir.
Müşterilerimize hızlı, kaliteli ve çevreci çözümler sunarak, yalnızca Türkiye'de değil, uluslararası pazarda da güvenilir bir marka olmayı hedefliyoruz.
Dijital dönüşüm ve akıllı ambalaj çözümleriyle sektörde fark yaratan projelere imza atmaya devam edeceğiz.`,
        backgroundColor: "secondary.main",
        textColor: "primary",
        imagePosition: "left",
      },
      mission: {
        title: "Misyonumuz",
        description: `Misyonumuz; markaların kimliğini en iyi şekilde yansıtan yenilikçi, yüksek kaliteli ve sürdürülebilir baskı çözümleri sunmaktır.
Müşteri beklentilerini aşmayı hedefleyerek, dayanıklı malzemeler ve yaratıcı tasarımlarla projelerinizi hayata geçiriyoruz.
Teknolojik gelişmeleri yakından takip ederek, çevreye duyarlı üretim süreçleriyle sektörde sorumlu ve sürdürülebilir bir firma olarak hizmet veriyoruz.`,
        backgroundColor: "primary.main",
        textColor: "secondary",
        imagePosition: "right",
      },
    }
  };

export default aboutData;
