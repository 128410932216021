// src/data/achievementsData.js

const achievementsData = {
    title: "",
    subTitle: "Yıllık Rakamlarla Çok Başarılıyız",
    achievements : [
    {
      number: "25",
      label: "Yıllık Deneyim"
    },
    {
      number: "4k+",
      label: "Mutlu Müşteri"
    },
    {
      number: "800+",
      label: "Özel Ambalaj Tasarımı"
    },
    {
      number: "5k+",
      label: "Başarılı Sipariş"
    },
    {
      number: "523",
      label: "Global Sipariş"
    }
  ]
};
  
  export default achievementsData;
  