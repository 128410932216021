
const quoteData = {
  title: "Teklif Al",
  description:
    "Ürün veya hizmetinizin özelliklerini belirleyerek size en uygun fiyat teklifini almak için lütfen formu doldurunuz.",
  button: {
    text: "Teklif Gönder",
  },
  placeholders: {
    jobType: "İşin Tipini Seçin *",
    paperType: "Varsa Kağıt Tipi Seçin",
    paperWeight: "Varsa Gramaj Seçin",
    coatingType: "Varsa Kaplama Türü Seçin",
    bindingType: "Varsa Ciltleme Türü Seçin",
    colorType: "Renk Seçin *",
    printingType: "Baskı Türü Seçin *",
    additionalRequests: "Özel isteklerinizi veya ürün detaylarını buraya yazabilirsiniz..."
  },
  alert:{
    success: "Teklif talebiniz başarıyla gönderildi! En kısa sürede sizinle iletişime geçeceğiz."
  }
};

export default quoteData;
