// İş Türleri (JobTypes)
export const JobTypes = [
  // Basılı Ürünler
  "Dergi",
  "Broşür",
  "El İlanı",
  "Katalog",
  "Kitap",
  "Defter",
  "Afiş/Poster",
  "Duvar Takvimi",
  "Ajanda",
  "Davetiye",
  "Ürün Etiketi",
  "Karton Kutu",
  "Doypack",
  "Sticker",
  "Bloknot",
  "Cepli Dosya",
  "Menü",
  "Amerikan Servis",
  "Kitap Ayracı",
  "Magnet",
  "Kapı Askısı",
  "Masaüstü Takvim",
  "Sertifika/Diploma",
  "Yaka Kartı",
  "Zarf",
  "CD Kabı",
  "Antetli Kağıt",
  "Kartvizit",
  "Yapışkanlı Not Kağıdı",
  "Roll Up Banner",
  "Pelür Kağıt",

  // Ambalaj Ürünleri
  "Karton Poşet",
  "Karton Bardak",
  "E-Ticaret Kargo Kutusu",
  "Gıda Ambalajı",

  // Promosyon Ürünleri
  "Anahtarlık",
  "Bez Çanta",
  "Kupa Bardak",
  "Bardak Tutucu",
  "Rozet",
  "T-shirt",
  "Şapka",
  "Kalem",
  "Powerbank",
  "USB Bellek",
  "Mouse Pad",
  "Diğer (Detayları ek talepler bölümünden yazabilirsiniz)"
];
  
  // Kağıt Türleri (PaperTypes)
  export const PaperTypes = [
    "Kuşe (Parlak)",
    "Kuşe (Mat)",
    "Amerikan Bristol",
    "1. Hamur",
    "Kraft (Doğal Kahverengi)",
    "Kraft (Beyaz)",
    "Fotokopi Kağıdı",
    "Metalize Kağıt (Altın)",
    "Metalize Kağıt (Gümüş)",
    "PVC (Şeffaf)",
    "PVC (Beyaz)",
    "Deri Kaplamalı Kağıt",
    "Mukavva (Sert Kapak)",
    "Sticker Kağıdı (Parlak)",
    "Sticker Kağıdı (Mat)",
    "Termal Kağıt",
    "Silikonlu Kağıt",
    "Bond Kağıt",
    "Saman Kağıt",
    "Şamua Kağıt",
    "Tual Kağıt",
    "Pelur Kağıt",
    "Folyo Kaplamalı Kağıt"
  ];
  
  
  // Kağıt Gramajları (PaperWeights)
  export const PaperWeights = [
    "45 g/m²",
    "60 g/m²",
    "70 g/m²",
    "80 g/m²",
    "90 g/m²",
    "100 g/m²",
    "115 g/m²",
    "120 g/m²",
    "135 g/m²",
    "150 g/m²",
    "170 g/m²",
    "200 g/m²",
    "230 g/m²",
    "250 g/m²",
    "280 g/m²",
    "300 g/m²",
    "350 g/m²",
    "400 g/m²",
    "450 g/m²",
    "600 g/m²"
  ];

  export const CoatingTypes = [
    "Selefon (Parlak)",
    "Selefon (Mat)",
    "UV Lak (Parlak)",
    "UV Lak (Mat)",
    "Spot Lak",
    "Varak Yaldız (Altın)",
    "Varak Yaldız (Gümüş)",
    "Gofre (Kabartma)",
    "Lak Kaplama",
    "Soft Touch (Kadife Doku)",
    "Lamina (Şeffaf Plastik Kaplama)",
    "Termal Kaplama"
  ];
  
  export const BindingTypes = [
    "Tel Dikiş",
    "Amerikan Cilt",
    "Spiralli Cilt (Metal)",
    "Spiralli Cilt (Plastik)",
    "Karton Kapak",
    "Cilt Bezi Kapak",
    "Sıcak Yapıştırma",
    "Dikişli Cilt",
    "Takviyeli Sırt",
    "Yapışkanlı Sırt"
  ];
  
  export const ColorOptions = [
    "Tek Renk (Siyah)",
    "2 Renk",
    "3 Renk",
    "4 Renk (CMYK)",
    "Pantone Renk",
    "Altın Varak",
    "Gümüş Varak",
    "Fosforlu Renk",
    "Neon Renk",
    "Pastel Tonlar"
  ];
  
  export const PrintingTypes = [
    "Ofset Baskı",
    "Dijital Baskı",
    "Serigrafi Baskı",
    "Flekso Baskı",
    "UV Baskı",
    "Tampon Baskı",
    "Letterpress Baskı",
    "Gravür Baskı",
    "Lazer Kazıma",
    "Kabartma Baskı"
  ];
  