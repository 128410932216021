const contactData = {
    title: "İletişim",
    description: "Bizimle iletişime geçmek için aşağıdaki formu doldurun veya doğrudan iletişim bilgilerimizden ulaşın.",
    
    companyInfo: {
      address: "Maltepe Mah. Litros Yolu 2. Matbaacılar Sitesi C Blok Kat 5 No: 23 Zeytinburnu/İstanbul, Türkiye",
      phone: "+90 544 913 71 07",
      email: "info@medyaambalaj.com",
      whatsappLink: "https://wa.me/905449137107"
    },
  
    formFields: [
      { label: "Adınız", name: "name", type: "text" },
      { label: "E-Posta", name: "email", type: "email" },
      { label: "Mesajınız", name: "message", type: "textarea", rows: 4 }
    ],
  
    mapEmbedUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.0359274861435!2d28.91588717635405!3d41.02446991842707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caba5c715bf09b%3A0x479859c6aa7a624a!2sMedya%20Ambalaj%20%7C%20Profesyonel%20Matbaa%20ve%20Ambalaj%20%C3%87%C3%B6z%C3%BCmleri!5e0!3m2!1str!2str!4v1742483580907!5m2!1str!2str"
  };
  
  export default contactData;
  