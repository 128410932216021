import logo from '../../assets/logo/logo-horizontal-light.png';

const footerData = {
    companyInfo: {
      logo: logo,
      description: 'Yüksek Kalite Baskı ve Profesyonel Ambalaj Çözümlerinde 25 Yıllık Tecrübe'
    },
    quickMenu: [
      { label: 'Hakkımızda', link: '/about' },
      { label: 'Referanslar', link: '/references' },
      { label: 'Hizmetler', link: '/services' },
      { label: 'Ürünler', link: '/products' },
      { label: 'Blog', link: '/blog' },
      { label: 'SSS', link: '/faq' },
      { label: 'İletişim', link: '/contact' },
      { label: 'Teklif Al', link: '/quote' }
    ],
    productCategories: [
      'Basılı Ürünler',
      'Ambalaj Ürünleri',
      'Promosyon Ürünleri'
    ],
    contactInfo: {
      email: 'info@medyaambalaj.com',
      location: 'Maltepe Mah. Litros Yolu 2. Matbaacılar Sitesi C Blok Kat 5 No: 23 Zeytinburnu/İstanbul, Türkiye',
      whatsapp: '+90 544 913 71 07',
      whatsappLink: 'https://wa.me/905449137107',
      locationLink: 'https://maps.app.goo.gl/qJ4fLCYHaHCqYsvg9'
    },
    socialLinks: {
      instagram: 'https://www.instagram.com/medyaambalaj.tr',
      linkedin: 'https://www.linkedin.com/company/medya-ambalaj/posts/?feedView=all'
    },
    copyright: '© 2025 Medya Ambalaj. Tüm Hakları Saklıdır.'
  };
  
  export default footerData;
  