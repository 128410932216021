import algida from '../../assets/images/references/algida.webp';
import cocaCola from '../../assets/images/references/coca-cola.webp';
import damatTween from '../../assets/images/references/damat-tween.webp';
import devaHolding from '../../assets/images/references/deva.webp';
import dsDamat from '../../assets/images/references/ds-damat.webp';
import englishHome from '../../assets/images/references/english-home.webp';
import flyingTiger from '../../assets/images/references/flying-tiger.webp';
import karaca from '../../assets/images/references/karaca.webp';
import kinder from '../../assets/images/references/kinder.webp';
import manchesterUnited from '../../assets/images/references/manchester-united.webp';
import tommy from '../../assets/images/references/tommy-hilfiger.webp';
import pandora from '../../assets/images/references/pandora.webp';
import panoEffect from '../../assets/images/references/panoffect.webp';
import pierreCardin from '../../assets/images/references/pierre-cardin.webp';

const referencesData = {
  title: "Referanslarımız",
  subTitle: "Sektör Liderlerinin Güvendiği İsim",
  description: "Güvenilir iş ortaklarımız ve mutlu müşterilerimizden oluşan bazı referanslarımızı aşağıda görebilirsiniz.",
  logos: [
    { id: 1, src: algida },
    { id: 2, src: cocaCola },
    { id: 3, src: damatTween },
    { id: 4, src: devaHolding },
    { id: 5, src: dsDamat },
    { id: 6, src: englishHome },
    { id: 7, src: flyingTiger },
    { id: 8, src: karaca },
    { id: 9, src: kinder },
    { id: 10, src: manchesterUnited },
    { id: 11, src: tommy },
    { id: 12, src: pandora },
    { id: 13, src: panoEffect },
    { id: 14, src: pierreCardin }
  ]
};

export default referencesData;
